import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { AuthService } from '../../services/auth.service';
import { Router, ActivatedRoute,Params } from '@angular/router';
import {UserService} from "../services/user.service";
import {ToastrService} from "ngx-toastr";



@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
ResponseResetForm: FormGroup;
  errorMessage: string;
  successMessage: string;
  resetToken: null;
  CurrentState: any;
  IsResetFormValid = true;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,private userService: UserService,private toaster: ToastrService ) {

   this.route.queryParams.subscribe((params: Params) => {
    localStorage.setItem("token", params.token);
    this.toaster.success('Email Verify Please Enter password')
  });
  }
  ngOnInit(): void {
      this.Init();
  }



  Init() {
    this.ResponseResetForm = this.fb.group(
      {
        resettoken: [this.resetToken],
        newPassword: ['', [Validators.required, Validators.minLength(4)]],
        confirmPassword: ['', [Validators.required, Validators.minLength(4)]]
      }
    );
  }


  ResetPassword(form:any) {
     const new_password = form.controls.newPassword.value;
    const confirm_password = form.controls.confirmPassword.value;
    if(new_password.length<8){
      this.toaster.error('Please enter minimum 8 digit password')
    }
    else if(new_password !== confirm_password){
      this.toaster.error('Password and confirm password does not match')
    }else{
       if (form.valid) {
      this.IsResetFormValid = true;
      let password=form.get('confirmPassword').value;
      let token=localStorage.getItem('token');
      console.log(token);
      this.userService.resetPassword({"resetToken":token,"newPassword":password}).subscribe(
        data => {
         this.toaster.success('Password change succesfully.');
         localStorage.removeItem('token');
         this.router.navigate(['home']);
        },
        err => {
          if (err.error.message) {
            this.errorMessage = err.error.message;
          }
        }
      );
    } else { this.IsResetFormValid = false; }
    }
   
  }
}
