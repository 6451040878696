import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LeadsService {
  constructor(public http: HttpClient) {
  }
  leadListClick:string;
  leadLatList:number;
  leadLongList:number;

  public updateSelectedLead: BehaviorSubject<string> = new BehaviorSubject('');

  baseUrl = environment.serviceUrls.leads;

  public fetchLeadById(leadId: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/lead/${leadId}`)
  }

  public searchLeads(options: { searchType: string, searchText: string }, filters = []): Observable<any> {
    return this.http.post(`${this.baseUrl}/leads/search/${options.searchType}/${options.searchText}`, {filters})
  }

  public getResultsCount(options: { searchType: string, searchText: string }, filters: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/leads/search/count/${options.searchType}/${options.searchText}`, {filters})
  }

  public createLeadSurvey(options: { leadId: string, purchased: boolean, closed: boolean }): Observable<any> {
    return this.http.post(`${this.baseUrl}/lead/survey`, options)
  }

  public getAllLeadSurvey(): Observable<any> {
    return this.http.get(`${this.baseUrl}/admin/lead/survey`)
  }

  public leadSurveyAction(options: any): Observable<any> {
    return this.http.put(`${this.baseUrl}/admin/lead/survey`, options)
  }

  public searchBounds(type: string, value: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/search/bounds/${type}/${value}`)
  }

  public saveLeadData(updateOpts: any): Observable<any> {
    return this.http.put(`${this.baseUrl}/lead/save`, updateOpts)
  }

  public getSavedLeads(): Observable<any> {
    return this.http.get(`${this.baseUrl}/leads/saved`)
  }

    public deleteSavedLeads(options:any): Observable<any> {
    return this.http.post(`${this.baseUrl}/leads/saved/delete`,options)
  }

   public deleteSavedFillterLeads(options:any): Observable<any> {
    return this.http.delete(`${this.baseUrl}/filters/saved?id=${options}`);
  }
  public getFiltersData(): Observable<any> {
    return this.http.get(`${this.baseUrl}/filters`)

  }

  public getSearchHistory(): Observable<any> {
    return this.http.get(`${this.baseUrl}/leads/search/history`)
  }

  public getSavedFilters(): Observable<any> {
    return this.http.get(`${this.baseUrl}/filters/saved`)
  }

  public saveFilters(body: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/filters/save`, body)
  }

  public getLeadImages(opts: any): Observable<any> {
    return this.http.get(`${this.baseUrl}/lead/images?lat=${opts.latitude}&long=${opts.latitude}`)
  }


  public setSelectedLead(position: string) {
    this.updateSelectedLead.next(position);
  };
}
