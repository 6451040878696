 <div class="searchHistory">
  <div class="container">
    <div class="backHome">
      <a href="/dashboard" class="btn back">
        <img src="assets/images/home.png">Back To Home
      </a>
    </div>
    <div class="content">
      <h4>Search History</h4>
      <div class="heading">
        <p>Search Name</p>
        <p class="pr-4">Date</p>
        <p>Search</p>
      </div>
      <div class="historyCard">
        <div class="cards" *ngFor="let data of searchHistoryData;">
          <p>{{data.searchText}}</p>
          <p>{{ data.date }}</p>
          <button class="btn searchBtn" (click)="searchById(data)">Search</button>
        </div>
      </div>
    </div>

  </div>
</div>
