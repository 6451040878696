<div class="container-fluid form">
    <!-- <div class="row" *ngIf="CurrentState=='Wait'">
        <div class="col-md-12  close-form">
            <h2> Please Wait...</h2>
        </div>
    </div> -->
    <div class="row"
        *ngIf="CurrentState=='NotVerified'">
        <div class="col-md-12">
            <h2> Invalid URL.</h2>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
            <div class="card card-signin my-5">
                <div class="card-body">
                    <h5 class="card-title text-center">Set New Password</h5>
                    <div>
                        <div id="errorMsg" *ngIf="errorMessage">
                            <span>{{errorMessage}}</span>
                        </div>
                        <!-- <div id="successMsg" *ngIf="successMessage">
                            <span>{{successMessage}}</span>
                        </div> -->
                        <form action="" [formGroup]="ResponseResetForm" (ngSubmit)="ResetPassword(ResponseResetForm)">
                            <div class="form-group">
                                <input _ngcontent-c0="" class="form-control form-control-lg" placeholder="Password"
                                    type="password" id="password" formControlName="newPassword" />
                                <!-- <span *ngIf="!ResponseResetForm.get('newPassword').valid && !IsResetFormValid"
                                    class="help-block">Password is required with atleast 4 characters.</span> -->
                            </div>
                            <div class="form-group">
                                <input _ngcontent-c0="" class="form-control form-control-lg"
                                    placeholder="Confirm Password" type="password" id="cpassword"
                                    formControlName="confirmPassword" />
                                <!-- <span *ngIf="!ResponseResetForm.get('confirmPassword').valid && !IsResetFormValid"
                                    class="help-block">. </span> -->
                                <!-- <span
                                    *ngIf="ResponseResetForm.get('confirmPassword').valid && (ResponseResetForm.get('confirmPassword').value != ResponseResetForm.get('newPassword').value) && !IsResetFormValid"
                                    class="help-block">Confirm Password does not match with password.</span> -->
                            </div>
                            <div class="form-group">
                                <div>

                                    <button type="submit" class="  btn btn-primary">Update
                                        Password</button>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>