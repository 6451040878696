import { NgModule } from '@angular/core';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardListComponent } from './dashboard-list/dashboard-list.component';
import { DashboardMapComponent } from './dashboard-map/dashboard-map.component';
// map components to show markers and implement map
import {LeafletModule} from "@asymmetrik/ngx-leaflet";
import {LeafletMarkerClusterModule} from "@asymmetrik/ngx-leaflet-markercluster";
// angular data tables for the list view of leads
import {AgGridModule} from "ag-grid-angular";
import { LeadDetailsComponent } from './lead-details/lead-details.component';
import { FiltersComponent } from './filters/filters.component';
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { Select2Module } from 'ng-select2-component';
import { ComponentsModule } from '../components.module';
import {
   MapsAPILoader
} from '@agm/core';
import {
   isPlatformBrowser
} from '@angular/common';

@NgModule({
  exports: [
    DashboardListComponent,
    DashboardMapComponent,
    LeadDetailsComponent,
    FiltersComponent
  ],
  declarations: [
    DashboardListComponent,
    DashboardMapComponent,
    LeadDetailsComponent,
    FiltersComponent,
    DashboardComponent
  ],
  imports: [
    LeafletModule,
    LeafletMarkerClusterModule,
    AgGridModule.withComponents([DashboardListComponent]),
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    Select2Module,
    ComponentsModule
  ],
  providers: [
  ],
})
export class DashboardModule { }
