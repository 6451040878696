import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  baseUrlPayments = environment.serviceUrls.payments;
  baseUrl = environment.serviceUrls.user;

  public isLoggedIn = false
  private _isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject(this.isLoggedIn);
  isUserLoggedIn = this._isLoggedIn.asObservable();

  constructor(private http: HttpClient, private router: Router) {
    const token = localStorage.getItem("token");
    if (token && token.length > 0) {
      this.isLoggedIn = true;
      this._isLoggedIn.next(this.isLoggedIn);
    }
  }

  public addPaymentMethod(paymentProfile: any): Observable<any> {
    console.log(paymentProfile)
    return this.http.post(`${this.baseUrlPayments}/payments/create/payment-profile`, {paymentProfile});
  }

  public getUserSubscription(): Observable<any> {
    return this.http.get(`${this.baseUrlPayments}/payments/subscription`);
  }

  public getPaymentMethods(): Observable<any> {
    return this.http.get(`${this.baseUrlPayments}/payments/payment-profile`);
  }

    public deletePaymentMethods(options:any): Observable<any> {
    return this.http.delete(`${this.baseUrlPayments}/payments/delete/payment-profile?id=${options}`);
  }
  public createSubscription(options: any): Observable<any> {
    return this.http.post(`${this.baseUrlPayments}/payments/subscription`, options);
  }

  public updateSubscription(options: any): Observable<any> {
    return this.http.put(`${this.baseUrlPayments}/payments/subscription`, options);
  }

  public loginUser(userData: { email: string, password: string }): Observable<any> {
    return this.http.post(`${this.baseUrl}/user/login`, userData, {observe: 'response'});
  }

  public signupUser(userData: { email: string, password: string }): Observable<any> {
    return this.http.post(`${this.baseUrl}/user`, userData, {observe: 'response'});
  }

  public getProfileDetails(): Observable<any> {
    return this.http.get(`${this.baseUrl}/user/profile`);
  }

  public updateUser(options: {}): Observable<any> {
    return this.http.put(`${this.baseUrl}/user`, options);
  }
  public updateThreshold(options:any): Observable<any> {
    return this.http.get(`${this.baseUrl}/user/threshold?value=${options}`);
  }

  public changePassword(options: {}): Observable<any> {
    return this.http.put(`${this.baseUrl}/user/change-password`, options);
  }

  public forgetPassword(options:any): Observable<any> {
    return this.http.put(`${this.baseUrl}/user/forgot-password`, options);
  }

  public resetPassword(options: any): Observable<any> {
    return this.http.put(`${this.baseUrl}/user/reset-password`, options);
  }

  public contactUsEmail(options:any): Observable<any> {
    return this.http.post(`${this.baseUrl}/contactUs/send-email`, options);
  }

  public subscribeEmail(options:any): Observable<any> {
    return this.http.post(`${this.baseUrl}/subscribe/send-email`, options);
  }
  public sendLeadsEmail(leads: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/user/send-email`, {leads});
  }

  public updateLoginStatus(loginStatus: boolean) {
    this.isLoggedIn = loginStatus;
    this._isLoggedIn.next(this.isLoggedIn);
    if (!loginStatus) {
      localStorage.removeItem("token");
      this.router.navigate(['']);
    }
  }

  setUser(user: any) {
    localStorage.setItem("user", JSON.stringify({
      city: user.city, role: user.role, threshold: user.threshold, name: user.name
    }));
  }

  getUser(): string | null {
    let user = localStorage.getItem("user");
    if (user)
      user = JSON.parse(user);
    return user;
  }

}
