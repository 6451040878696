
<div class="property-view" [ngClass]="showLeadData" xmlns="http://www.w3.org/1999/html">
  <app-loader></app-loader>
  <div id="leadDetailsImage">
    <!-- header -->
    <div class="propertyHeader">
      <div class="leftPart">
        <h2>Leads in {{searchKeyword}}</h2>
        <div [hidden]="pdfDownloadInProgress" class="controls">
          <div class="arrow" (click)="selectLead('next')">
            <img src="assets/images/property-view/left.svg" alt="prev">
          </div>
          <div class="arrow" (click)="selectLead('previous')">
            <img src="assets/images/property-view/right.svg" alt="next">
          </div>
        </div>
      </div>
      <div [hidden]="pdfDownloadInProgress" class="rightPart">
        <div class="cross" id="cross" (click)="close()">
          <img src="assets/images/property-view/cross.svg" alt="cancel">
        </div>
      </div>
    </div>

    <!-- property image -->

    <div #leadImage class="propertyImg">
      <img allowTaint="true"
           src="https://maps.googleapis.com/maps/api/streetview?location={{images[0]}},{{images[1]}}&size=800x400&key={{apiKey}}"/>
    </div>
    <a (click)="getStreetView()" class="btn actions-btn" title="streetView" data-toggle="modal" data-target="#streetModal">Get Street View</a>
    <!-- property down-header -->

    <div class="down-header">
      <div class="address">
        <h2>{{leadDetails.address}}</h2>
      </div>
      <div [hidden]="pdfDownloadInProgress" class="btnsGrp">
        <a (click)="saveLead('save')" class="btn actions-btn" data-toggle="tooltip" data-placement="top" title="Save"
           [ngClass]="savedLeadData.isSaved ? 'tab-selected': ''">
          <img src="assets/images/dashboard/sub-header/Save.png">
          <span class="textHidden">{{savedLeadData.isSaved ? 'Saved' : 'Save'}}</span>
        </a>
        <a (click)="sendLeadsEmail()" class="btn actions-btn" data-toggle="tooltip" data-placement="top" title="Email">
          <img src="assets/images/dashboard/sub-header/mail.png">
          <span class="textHidden">Email</span>
        </a>
        <a (click)="downloadLead()" class="btn actions-btn" data-toggle="tooltip" data-placement="top" title="Download">
          <img src="assets/images/dashboard/sub-header/download.png">
          <span class="textHidden">Download</span>
        </a>
        <!-- <a class="btn actions-btn">
            <img src="assets/images/dashboard/sub-header/push.png">
            <span>Push</span>
        </a> -->
        <div class="btn-group dropdown dropleft">
          <a class="more-items" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img src="assets/images/dashboard/sub-header/more-dots.png">
          </a>
          <div class="dropdown-menu">
            <a class="dropdown-item" data-toggle="modal" data-target="#surveyForm">Lead Survey</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- tabs section -->

  <div id="leadDetails" class="propertyTabs">
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a #leadDetailsButton class="nav-link active" data-toggle="tab" href="#lead">Lead Details</a>
      </li>
      <li class="nav-item">
        <a *ngIf="savedLeadData.isSaved" #savedInfoButton class="nav-link" data-toggle="tab" href="#saved">Saved
          Info</a>
      </li>
    </ul>

    <!-- Tab panes -->

    <div class="tab-content">
      <div class="tab-pane container active" id="lead">
        <h4>Motivational Influences</h4>
        <!-- cards -->
        <div class="cards-section" *ngFor="let tag of leadDetails.tags">
          <div class="card">
            <div class="top bg-skyblue">
              <div class="tag">
                <img src="assets/images/{{tag.icon}}" alt="tags">
                <h6>{{tag.label}}</h6>
              </div>
              <div>
                <img src="assets/images/property-view/info.png" alt="info" class="info">
              </div>
            </div>
            <div class="down">
              <div class="tag">
                <img src="assets/images/property-view/clock.png" alt="tags">
                <h6>missing data</h6>
              </div>
              <div class="tag">
                <img src="assets/images/property-view/file-text.png" alt="tags">
                <h6>missing data</h6>
              </div>
            </div>
          </div>
        </div>
        <!-- details -->
        <div class="details">
          <h4>Owner Information As Reported By The Tax Assessor Of</h4>
          <p>{{leadDetails.ownerAddress}}</p>

          <h4>Name</h4>
          <p>{{leadDetails.ownerName}}</p>

          <h4>Pricing Details</h4>
          <div class="pricingDetails">
            <div class="row subDetail">
              <div class="col-4 detail">
                <h3>Land Value</h3>
                <p>{{formatPrice(leadDetails.landValue)}}</p>
              </div>
              <div class="col-4 detail">
                <h3>Improvement Value</h3>
                <p>{{formatPrice(leadDetails.improvementValue)}}</p>
              </div>
              <div class="col-4 detail">
                <h3>Last year Tax Amount</h3>
                <p>{{formatPrice(leadDetails.lastYearTax)}}</p>
              </div>
              <div class="col-4 detail">
                <h3>Sale Price</h3>
                <p>{{formatPrice(leadDetails.salePrice)}}</p>
              </div>
            </div>
          </div>

          <h4>Other Details</h4>
          <div class="otherDetails">
            <div class="row subDetail">
              <div class="col-4 detail">
                <h3>Build Description</h3>
                <p>{{leadDetails.buildDescription || ''}}</p>
              </div>
              <div class="col-4 detail">
                <h3>Property Class</h3>
                <p>{{leadDetails.propertyClass}}</p>
              </div>
              <div class="col-4 detail">
                <h3>Year Built</h3>
                <p>{{leadDetails.yearBuilt}}</p>
              </div>
              <div class="col-4 detail">
                <h3>Municipaliy Name</h3>
                <p>{{leadDetails.municipalityName}}</p>
              </div>
              <div class="col-4 detail">
                <h3>Match Summary</h3>
                <p>{{leadDetails.matchSummary}}</p>
              </div>
              <div class="col-4 detail">
                <h3>No. of Units</h3>
                <p>{{leadDetails.numberOfUnits}}</p>
              </div>
              <div class="col-4 detail">
                <h3>Absentee Category</h3>
                <p>{{leadDetails.absenteeCategory || ''}}</p>
              </div>
              <div class="col-4 detail">
                <h3>Tax Record Link</h3>
                <p><a href="{{leadDetails.taxRecordLink}}">Tax Link</a></p>
              </div>
              <div class="col-4 detail">
                <h3>Owners Category</h3>
                <p>{{leadDetails.ownersCategory}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-pane container fade" id="saved">
        <!-- tags add part -->
        <div class="tagsPart">
          <div class="tagAdd">
            <p>Tags :</p>
            <div class="plus" (click)="enableEditMode('tags')">
              <i class="fa fa-plus-circle"></i>
            </div>
            <div class="addInput" *ngIf="editMode==='tags'">
              <input [(ngModel)]="tag" class="form-control" type="text" placeholder="Add a text for tag"/>
              <button class="btn add" (click)="saveLead('tag')">Add</button>
            </div>
          </div>
          <div class="badges" *ngFor="let tag of savedLeadData.tags; let i = index">
            <span class="badge tag-badge">{{tag}} <i (click)="updateSavedData('tag', i)" class="fa fa-times-circle"></i></span>&nbsp;&nbsp;
          </div>
        </div>
        <br>
        <!-- notes add part -->
        <div class="notesPart">
          <div class="notesAdd">
            <p>Notes :</p>
            <div class="plus" (click)="enableEditMode('notes')">
              <i class="fa fa-plus-circle"></i>
            </div>
            <div class="addInput" *ngIf="editMode === 'notes'">
              <input [(ngModel)]="note" class="form-control" type="text" placeholder="Add a text for notes"/>
              <button class="btn add" (click)="saveLead('note')">Add</button>
            </div>
          </div>
          <div class="badges" *ngFor="let note of savedLeadData.notes; let i = index;">
            <span class="badge note-badge">{{note}} <i (click)="updateSavedData('note', i)"
                                                       class="fa fa-times-circle"></i></span>&nbsp;&nbsp;
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Survey modal starts-->
<div class="modal fade" id="surveyForm" tabindex="-1" role="dialog" aria-labelledby="Survey"
     aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <!-- close button -->
      <button style="position: absolute; opacity: 0;" #closeSurveyModal type="button" class="close" data-dismiss="modal"
              aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
      <h2>Survey</h2>
      <div [formGroup]="surveyForm" class="modal-body">
        <div class="survey-text">
          <p>If you have recently made a transaction on this property, Please select from the following
            to share the type of transaction for our own internal gathering</p>
        </div>
        <div class="form-check">
          <label class="form-check-label">
            <input formControlName="purchased" type="checkbox" class="form-check-input" value="">Purchased
          </label>
        </div>
        <div class="form-check mb-5">
          <label class="form-check-label">
            <input formControlName="closed" type="checkbox" class="form-check-input" value="">Closed
          </label>
        </div>
        <button (click)="uploadSurvey(leadDetails.id)" class="btn signUp">Submit</button>
      </div>
    </div>
  </div>
</div>
<!--Survey modal ends-->



<!-- street view -->
<div class="modal" id="streetModal">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Street View</h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body px-0 py-0">
        <div id="streetview-container">
          <div id="streetview-map" #streetviewMap></div>
          <div id="streetview-pano" #streetviewPano></div>
        </div> 
      </div>

      <!-- Modal footer -->
      <div class="modal-footer">
        <button type="button" class="btn" data-dismiss="modal">Close</button>
      </div>

    </div>
  </div>
</div>