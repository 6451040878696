import {Component, Inject} from '@angular/core';
import { UserService } from "./services/user.service";
import { ConnectionService } from 'ng-connection-service';
import { DOCUMENT } from '@angular/common';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 're-data-frontend';
  isUserLoggedIn: boolean;

  isConnected = true;  
  noInternetConnection: boolean; 

  constructor(private userService: UserService,private connectionService: ConnectionService) {
    this.userService.isUserLoggedIn.subscribe((isUserLoggedIn)=>{
      this.isUserLoggedIn = isUserLoggedIn;
    })

    // connection check
    this.connectionService.monitor().subscribe(isConnected => {  
      this.isConnected = isConnected;  
      if (this.isConnected) {  
        this.noInternetConnection=false;
        $("#myModal").modal('hide');
        console.log(this.noInternetConnection)  
      }  
      else {  
        this.noInternetConnection=true;  
        $("#myModal").modal('show');
        $('#myModal').modal({
          backdrop: 'static',
          keyboard: false
        })
        console.log(this.noInternetConnection) 
      }  
    })  
  }
}
