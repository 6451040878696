<div class="savedLeads">
  <div class="container-fluid">
    <div class="backHome">
      <a href="/dashboard" class="btn back">
        <img src="assets/images/home.png">Back To Home
      </a>
    </div>
    <div class="content">
      <div class="d-flex justify-content-between mb-3">
        <h4>Saved Leads</h4>
        <div class="d-flex align-items-center">
          <div *ngIf="selectedLeads && selectedLeads.length > 0" class="d-flex align-items-center ml-lg-2 ml-0">
                 <a (click)="deleteSavedLeads()" class="btn actions-btn" data-toggle="tooltip" data-placement="top" title="Delete">
              <!-- <img src="assets/images/dashboard/sub-header/mail.png"> -->
              <i class="far fa-trash-alt"></i>
              <span class="textHidden">Delete</span>
            </a>
            <a (click)="sendLeadsEmail()" class="btn actions-btn" data-toggle="tooltip" data-placement="top" title="Email">
              <img src="assets/images/dashboard/sub-header/mail.png">
              <span class="textHidden">Email</span>
            </a>
            <a (click)="downloadPdf()" class="btn actions-btn" data-toggle="tooltip" data-placement="top" title="Download">
              <img src="assets/images/dashboard/sub-header/download.png">
              <span class="textHidden">Download</span>
            </a>
          </div>
          <button class="btn actions-btn columnBtn" data-toggle="modal" data-target="#modal-filter" >
            <img src="assets/images/column.png" data-toggle="tooltip" data-placement="top" title="Columns">
            <span class="textHidden" data-toggle="tooltip" data-placement="top" title="Columns">Columns</span>
          </button>
        </div>
      </div>
        <ag-grid-angular
          style="width: 100%; height: 600px;"
          class="ag-theme-material"
          [rowData]="rowData"
          [defaultColDef]="defaultColumnDefs"
          [columnDefs]="columnDefs"
          [rowStyle]="rowStyles"
          rowSelection="multiple"
          [rowMultiSelectWithClick]="true"
          (gridReady)="onGridReady($event)"
          (selectionChanged)="onSelectionChanged()"
        >
        </ag-grid-angular>
    </div>
  </div>
</div>


<!-- The Modal -->
<div class="modal fade" id="modal-filter">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
    
      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Customize Columns</h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>
      
      <!-- Modal body -->
    <form [formGroup]="filterSaveLeads">
      <div class="modal-body">
        <!-- use this (cdkDropListDropped)="drop($event)" -->
        <div cdkDropList class="list">
          <div class="box" *ngFor="let movie of leadColOptions;let i = index" formArrayName="leadSort" cdkDrag>
            <!-- <div class="custom-placeholder" *cdkDragPlaceholder></div> -->
            <div class="form-check">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input mr-3" [formControlName]="i">{{movie.label}}
              </label>
            </div>
          </div>
        </div>
 
      </div>
      
      <!-- Modal footer -->
      <div class="modal-footer">
        <button type="button" class="btn cancelBtn" data-dismiss="modal">Cancel</button>
        <button type="button" (click)="filterSaveLead();" data-dismiss="modal" class="btn saveBtn">Save</button>
      </div>
    </form>
      
    </div>
  </div>
</div>