import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  HostListener,
  ViewChild, ElementRef
} from '@angular/core';
import * as L from 'leaflet';
import 'leaflet.markercluster';
import {LeadsService} from "../../../services/leads.service";
import {FilteredLeadsService} from "../../../services/filtered-leads.service";
import {position} from "html2canvas/dist/types/css/property-descriptors/position";

const mapBoxKey = 'pk.eyJ1Ijoiaml0ZW5kcmEtemlnZ2xldGVjaCIsImEiOiJja3U3ejczdHgwZDZvMnFuejZxb2tibG93In0.sJ74fXcwsHkgQsdKVsywcg';
const customIcon = L.icon({
  iconUrl: '../../../assets/images/dashboard/marker.svg',
  iconSize: [20, 20], // size of the icon
  shadowSize: [20, 20], // size of the shadow
  iconAnchor: [22, 94], // point of the icon which will correspond to marker's location
  shadowAnchor: [4, 62],  // the same for the shadow
  popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
});
const selectedIcon = L.icon({
  iconUrl: '../../../assets/images/dashboard/marker.svg',
  iconSize: [40, 40], // size of the icon
  shadowSize: [40, 40], // size of the shadow
  iconAnchor: [42, 94], // point of the icon which will correspond to marker's location
  shadowAnchor: [4, 62],  // the same for the shadow
  popupAnchor: [-3, -76] // point from which the popup should open relative to the iconAnchor
});

@Component({
  selector: 'app-dashboard-map',
  templateUrl: './dashboard-map.component.html',
  styleUrls: ['./dashboard-map.component.scss']
})
export class DashboardMapComponent implements OnInit {
  leads!: any;
  dashboardView: string;
  split: boolean;
  arrowClick = false;
  center: any

  // leads data searched by user
  @Input('leadsData') set handleLeadsData(leadsData: any) {
    this.leads = leadsData;
    // update the markers
    this.markerClusterData = this.generateMarkers(this.leads)
  }

  // search data to fetch map bounds
  @Input('searchData') set handleSearchData(searchData: any) {
    // draw polygon
    if (searchData.type)
      this.leadsService.searchBounds(searchData.type, searchData.keyword).subscribe((data: any) => {
        this.drawPolygon(data.data[0].geojson);
      })
  }

  // should be triggered on dashboard view change
  @Input('dashboardView') set setDashboardView(dashboardView: string) {
    this.dashboardView = dashboardView;
    this.split = dashboardView === 'hybrid';
    window.dispatchEvent(new Event('resize'));
  }

  // to send lead id back to the dashboard component
  @Output() leadDetailsId = new EventEmitter<string>();

  // leaflet map options
  layers = [];
  bounds: L.LatLngBounds;
  options: L.MapOptions;
  markerClusterOptions: L.MarkerClusterGroupOptions
  markerClusterGroup = L.markerClusterGroup();
  markerClusterData: any;
  selectedMarkerIndex: number;

  constructor(private leadsService: LeadsService, private filteredLeadsService: FilteredLeadsService,
              private ref: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.initMap();
  }

  markerClusterReady(group: L.MarkerClusterGroup) {
    this.markerClusterGroup = group;
  }

  generateMarkers(markerData: any) {
    if (markerData) {
      return markerData.map((lead: { latitude: string; longitude: string; id: any; address: string; }, index: number) => {
        return L.marker([parseFloat(lead.latitude), parseFloat(lead.longitude)],
          {title: lead.address, alt: `${lead.id}_${index}`, icon: customIcon})
          .on('click', this.markerClick.bind(this));
      })
    }
    return [];
  }

  markerClick(e: any) {
    const [leadId, index] = e.sourceTarget.options.alt.split("_");
    this.selectedMarkerIndex = parseInt(index);
    console.log("set selected marker", leadId);
    this.setSelectedMarker(leadId, e.latlng?.lat, e.latlng?.lng);
    this.leadDetailsId.next(leadId);
  }

  drawPolygon(data: any) {
    const geojson = L.geoJSON(data as any);
    // @ts-ignore
    this.layers = [geojson];
    this.bounds = geojson.getBounds();
  }

  initMap() {
    // let mapValue=JSON.parse(localStorage.getItem('map')|| '{}');

    this.options = {
      layers: [
        L.tileLayer(`https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=${mapBoxKey}`,
          {
            maxZoom: 25,
            tileSize: 512,
            zoomOffset: -1,
            attribution: '© <a href="https://apps.mapbox.com/feedback/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
          }),
      ],
      zoom: 12,
      center: L.latLng( 40.5939073639046, -74.2397991445038),
    }
    this.markerClusterOptions = {
      showCoverageOnHover: false,
      spiderfyOnMaxZoom: true,
    }
    this.markerClusterData = this.generateMarkers(this.leads);
    // watch next and previous button changes from lead details
    this.leadsService.updateSelectedLead.subscribe((position: string) => {
      console.log("possssssss:", position);
      if (position?.length > 0)
        this.selectMarker(position);
    })
  }

  mapResize(event: any) {
    if (event && event.target.attributionControl) {
      const bounds = event.target.attributionControl._map.getBounds();
      //set lat long in local storage
      let map={lat:bounds.lat,long:bounds.lng}
      localStorage.setItem('map',JSON.stringify(map));
      if (this.leads && this.leads?.length >= 0) {
        const filteredData = this.leads.filter((lead: any) => {
          const latlong = [parseFloat(lead.latitude), parseFloat(lead.longitude)];
          return !!bounds.contains(latlong);
        })
        this.filteredLeadsService.updateFilteredLeads(filteredData);
      }
    }
  }

  setSelectedMarker(leadId: string, leadLtd: number, leadLng: number) {
    console.log("Setting selected marker", leadId)
    this.markerClusterData.forEach((marker: any, index: number) => {
      const markerLeadId = marker.options.alt.split("_")[0];
      if (markerLeadId === leadId) {
        this.arrowClick = true;
        this.center = L.latLng(leadLtd , leadLng);
        marker.options.selected = true;
        console.log("Selected Marker", markerLeadId);
        this.markerClusterData[index].options.icon = selectedIcon;
        console.log("clicked marker", this.markerClusterData[index]);
        this.center = this.markerClusterData[index]._latlng;
        this.markerClusterData = [...this.markerClusterData]
        this.ref.detectChanges();
      } else if (marker.options.selected) {
        marker.options.selected = false;
        console.log("Previously Selected Marker", markerLeadId);
        this.markerClusterData[index].options.icon = customIcon;
        this.markerClusterData = [...this.markerClusterData]
        this.ref.detectChanges();
      }
    });
  }

  selectMarker(position: string) {
    if(!this.selectedMarkerIndex){
       // if we click through list 
      this.setSelectedMarker(this.leadsService.leadListClick,this.leadsService.leadLatList,this.leadsService.leadLongList);
      this.selectedMarkerIndex=5;
    }
    const index = position === 'next' ? this.selectedMarkerIndex + 1 : this.selectedMarkerIndex - 1;
    console.log(this.leads?.[index]?.id)
    const leadId = this.leads?.[index]?.id;
    console.log(this.leads?.[index]?.latitude);
    const leadLtd = this.leads?.[index]?.latitude;
    console.log(this.leads?.[index]?.longitude);
    const leadLng = this.leads?.[index]?.longitude;
    this.selectedMarkerIndex = index;
    this.setSelectedMarker(leadId, leadLtd, leadLng);
    this.leadDetailsId.next(leadId);
  }
  onCenterChange(dataEvent: any) {
    if(this.arrowClick) {
      this.bounds = dataEvent.toBounds(100);
      this.arrowClick = false;
    }
    console.log("Change center event",);
  }
}
