<div id="wrapper">
  <div leaflet id="page-content-wrapper" [ngClass]="split?'split-view': 'map-view'"
       [leafletOptions]="options"
       [leafletLayers]="layers"
       [leafletFitBounds]="bounds"
       [leafletMarkerCluster]="markerClusterData"
       [leafletMarkerClusterOptions]="markerClusterOptions"
       (leafletMarkerClusterReady)="markerClusterReady($event)"
       [leafletCenter]="center"
       (leafletCenterChange)="onCenterChange($event)"
       (leafletMapZoomEnd)="mapResize($event)">
  </div>
</div>
