<header id="header" class="home-header">
  <nav class="navbar navbar-expand-md bg-transparent navbar-dark">
    <a class="navbar-brand" href="#">
      <img src="assets/images/Logo.png" alt="logo">
      <!-- Real Estate Data -->
    </a>
    <div class="d-flex">
      <a class="nav-link btn btns web-none" data-toggle="modal" data-target="#modalRegisterForm">Try now</a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
        <span class="navbar-toggler-icon"><i class="fa fa-bars mt-2" style="color: black;"></i></span>
      </button>
    </div>
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto align-items-center">
        <li class="nav-item">
          <a class="nav-link" href="#features">Features</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#pricing">Pricing</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#contact">Contact Us</a>
        </li>
      </ul>
    </div>
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav ml-auto align-items-center">
        <li class="nav-item">
          <a class="nav-link" data-toggle="modal" (click)="openLogin()" data-target="#modalLoginForm">Log in</a>
        </li>
        <li class="nav-item mobile-none">
          <a class="nav-link btn btns" data-toggle="modal" data-target="#modalRegisterForm">Try now</a>
        </li>
      </ul>
    </div>

  </nav>
</header>

<!-- Header Section Ends -->

<!-- hero starts  -->
<div class="hero">
  <div class="container">
    <div class="content">
      <h2>Finding the property prospects is easier than ever before</h2>
      <p>Discover your next investment in a minute!</p>
      <button class="btn btns" data-target="#modalRegisterForm" data-toggle="modal">Start free 14 days Trial</button>
    </div>
  </div>
</div>

<!-- hero ends -->

<!-- category starts  -->
<div class="section2">
  <div class="container">
    <div class="category">
      <img src="assets/images/Intro.png" class="ipad">
      <h2>Explore</h2>
      <h2>by Category</h2>
    </div>
  </div>
  <div class="category-card">
    <div class="section">
      <div data-aos="fade-in" data-aos-duration="1000">
        <div class="all-cards">
          <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide>
              <div class="item">
                <div class="card">
                  <img src="assets/images/blue_circle.png">
                  <h3>Liens</h3>
                  <p>Lorem ipsum</p>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="item">
                <div class="card">
                  <img src="assets/images/green_triangle.png">
                  <h3>Bankruptcies</h3>
                  <p>Lorem ipsum</p>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="item">
                <div class="card">
                  <img src="assets/images/red_square.png">
                  <h3>Foreclosures</h3>
                  <p>Lorem ipsum</p>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="item">
                <div class="card">
                  <img src="assets/images/purple.png">
                  <h3>Absentee</h3>
                  <p>Lorem ipsum</p>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="item">
                <div class="card">
                  <img src="assets/images/skyblue.png">
                  <h3>Judgments</h3>
                  <p>Lorem ipsum</p>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="item">
                <div class="card">
                  <img src="assets/images/orange.png">
                  <h3>Vacancies</h3>
                  <p>Lorem ipsum</p>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="item">
                <div class="card">
                  <img src="assets/images/blue_circle.png">
                  <h3>Liens</h3>
                  <p>Lorem ipsum</p>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="item">
                <div class="card">
                  <img src="assets/images/green_triangle.png">
                  <h3>Bankruptcies</h3>
                  <p>Lorem ipsum</p>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="item">
                <div class="card">
                  <img src="assets/images/green_triangle.png">
                  <h3>Bankruptcies</h3>
                  <p>Lorem ipsum</p>
                </div>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- category ends  -->

<!-- section3 starts  -->

<div class="section3" id="features">
  <div class="container">
    <h2>Gown your business with peace of mind!</h2>
    <p>One platform with every property that you could ever invest in!</p>
    <div class="row">
      <div class="col-xl-4 col-lg-4 col-md-6 col-12">
        <div class="about-card">
          <img src="assets/images/zap.png" alt="">
          <h5>Robust Workflow</h5>
          <p>Lorem ipsum dollar ist si ameet, constreut adiption et litnsha lorem ipsum doller snhuytis.</p>
        </div>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-6 col-12">
        <div class="about-card">
          <img src="assets/images/sliders.png" alt="">
          <h5>Flexibility</h5>
          <p>Lorem ipsum dollar ist si ameet, constreut adiption et litnsha lorem ipsum doller snhuytis.</p>
        </div>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-6 col-12">
        <div class="about-card">
          <img src="assets/images/layout.png" alt="">
          <h5>User Friendly</h5>
          <p>Lorem ipsum dollar ist si ameet, constreut adiption et litnsha lorem ipsum doller snhuytis.</p>
        </div>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-6 col-12">
        <div class="about-card">
          <img src="assets/images/layers.png" alt="">
          <h5>Multiple layouts</h5>
          <p>Lorem ipsum dollar ist si ameet, constreut adiption et litnsha lorem ipsum doller snhuytis.</p>
        </div>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-6 col-12">
        <div class="about-card">
          <img src="assets/images/grid.png" alt="">
          <h5>Better components</h5>
          <p>Lorem ipsum dollar ist si ameet, constreut adiption et litnsha lorem ipsum doller snhuytis.</p>
        </div>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-6 col-12">
        <div class="about-card">
          <img src="assets/images/check-circle.png" alt="">
          <h5>Well organised</h5>
          <p>Lorem ipsum dollar ist si ameet, constreut adiption et litnsha lorem ipsum doller snhuytis.</p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- section 3 ends  -->

<!-- section 4 starts -->

<div class="section4" id="pricing">
  <div class="container">
    <h4>Plans & Pricing</h4>
    <p>Get instant access to the highest quality and most sought after motivated seller prospects in your market</p>
    <div class="pricing-card">
      <div class="details monthly">
        <h6>Monthly</h6>
        <h2>19.99 <span>$</span></h2>
        <div class="item-list">
          <ul>
            <li><img src="assets/images/Check Icon.png">2 GB of space</li>
            <li><img src="assets/images/Check Icon.png">14 days of backups</li>
            <li><img src="assets/images/Check Icon.png">Social integrations</li>
            <li><img src="assets/images/Check Icon.png">Client billing</li>
            <li><img src="assets/images/Check Icon.png">Remote access</li>
            <li><img src="assets/images/Check Icon.png">Custom domain</li>
            <li><img src="assets/images/Check Icon.png">24 hours support</li>
            <li class="not-available">Admin tools</li>
            <li class="not-available">Collaboration tools</li>
            <li class="not-available">User management</li>
          </ul>
        </div>
        <a href="#" class="btn trial-btn" data-target="#modalRegisterForm" data-toggle="modal">Start free 14 days Trial</a>
      </div>
      <div class="details yearly">
        <h6>Yearly</h6>
        <h2>49.99 <span>$</span></h2>
        <div class="item-list">
          <ul>
            <li><img src="assets/images/Check Icon.png">2 GB of space</li>
            <li><img src="assets/images/Check Icon.png">14 days of backups</li>
            <li><img src="assets/images/Check Icon.png">Social integrations</li>
            <li><img src="assets/images/Check Icon.png">Client billing</li>
            <li><img src="assets/images/Check Icon.png">Remote access</li>
            <li><img src="assets/images/Check Icon.png">Custom domain</li>
            <li><img src="assets/images/Check Icon.png">24 hours support</li>
            <li><img src="assets/images/Check Icon.png">Admin tools</li>
            <li><img src="assets/images/Check Icon.png">Collaboration tools</li>
            <li><img src="assets/images/Check Icon.png">User management</li>
          </ul>
        </div>
        <a href="#" class="btn trial-btn" data-target="#modalRegisterForm" data-toggle="modal">Start free 14 days Trial</a>
      </div>
    </div>
  </div>
</div>

<!-- section 4 ends -->

<!-- footer starts here  -->

<footer>
  <div class="container">
    <div class="contact" id="contact">
      <div class="row">
        <div class="col-lg-6 col-12">
          <div class="content">
            <h2>Contact Us!</h2>
            <p>Get instant access to the highest quality and most sought after motivated seller prospects in your
              market</p>
            <ul class="contact-list">
              <li><img src="assets/images/phone.png">
                <p>+1 123 456 7890</p></li>
              <li><img src="assets/images/mail-black.png">
                <p>info@realestatedata.com</p></li>
              <li><img src="assets/images/residance.png">
                <p>159 2nd St APT 301, Jersey City, NJ 07302</p></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6 col-12">
          <form [formGroup] ="contactUsForm">
            <div class="contact-form">
              <div class="form-group">
                <input type="text" class="form-control" id="name" formControlName = "name" placeholder="Full Name" autocomplete="off">
              </div>
              <div class="form-group">
                <input type="email" class="form-control" id="email" formControlName = "email" placeholder="Your Email" autocomplete="off">
              </div>
              <div class="form-group">
                <textarea class="form-control" id="message" rows="4" formControlName = "message" placeholder="Your Message"></textarea>
              </div>
              <a (click)="contactSubmit()" class="btn send-btn"> Send Message</a>
            </div>
          </form>
        </div>
      </div>

    </div>
    <div class="footerSection">
      <div class="footer-up mt-5">
        <div class="content-right">
          <a class="navbar-brand" href="#">
            <img src="assets/images/logo.svg" alt="logo">
            Real Estate Data
          </a>
        </div>
        <form [formGroup] ="subscribeForm">
        <div class="content-left">
          <input type="email" class="form-control" formControlName = "email"  placeholder="Your email">
          <a class="btn subscribe" (click)="subscribeSubmit()">Subscribe</a>
        </div>
        </form>
      </div>
      <hr>
      <div class="footer-down">
        <div class="content-left">
          <a href="#" class="footer-links">Contact Us</a>
          <a href="#" class="footer-links">Privacy Policy</a>
          <a href="#" class="footer-links">Terms</a>
        </div>
        <div class="content-right">
          <p>© 2021 Property Prospects. All rights reserved.</p>
        </div>
      </div>
    </div>
  </div>
</footer>

<!-- footer ends here -->

<!-- Login modal starts-->
<div class="modal fade" id="modalLoginForm" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <!-- sign in -->
    <div class="modal-content" *ngIf="!forgetPassword">
      <app-loader></app-loader>
      <!-- close button -->
      <button style="position: absolute; opacity: 0;" #closeModal type="button" class="close" data-dismiss="modal"
              aria-label="Close" (click)="clearErrors()">
        <span aria-hidden="true">&times;</span>
      </button>
      <h2>Sign In</h2>
      <div class="modal-body">
        <div [formGroup]="loginForm" (keyup.enter)="loginUser()">
          <div class="md-form">
            <img class="inputTag" src="assets/images/user.png">
            <input formControlName="email" placeholder="Your Email" type="email"
                   class="form-control validate" autocomplete="on">
          </div>
          <div class="md-form">
            <img class="inputTag" src="assets/images/lock.png">
            <input formControlName="password" placeholder="Your Password" type="password" id="login-pass"
                   class="form-control validate" autocomplete="new-password">
          </div>
          <div *ngIf="errorMsg && errorMsg.length > 0" class="md-form">
            <span class="text-danger">{{errorMsg}}</span>
          </div>
        </div>
        <button (click)="loginUser()" class="btn signIn">Sign In</button>
        <p (click)="forgetShow()" class="forget">Forget Password?</p>
      </div>
      <div class="modal-footer d-flex justify-content-center">
        <p>Don't have an Account?</p>
        <a href="javascript:;" data-target="#modalRegisterForm" data-toggle="modal"><span data-dismiss="modal"
          aria-label="Close" #closeModal (click)="clearErrors()">SignUp</span></a>
      </div>
    </div>
    <!-- forget Password -->
    <div class="modal-content" *ngIf="forgetPassword">
      <app-loader></app-loader>
      <!-- close button -->
      <button style="position: absolute; opacity: 0;" #closeModal type="button" class="close" data-dismiss="modal"
              aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
      <h2>Forget Password</h2>
      <form [formGroup] ="forgetPasswordForm">
      <div class="modal-body">

        <div class="md-form">
          <img class="inputTag" src="assets/images/user.png">
          <input formControlName="email" formControlName = "email" placeholder="Your Email" type="email" id="login-email"
                 class="form-control validate" autocomplete="on">
        </div>
        <div *ngIf="errorMsg && errorMsg.length > 0" class="md-form">
          <span class="text-danger">{{errorMsg}}</span>
        </div>
        <button (click)="postForgetPassword()" class="btn signIn">Send</button>
      </div>
      <div class="modal-footer d-flex justify-content-center">
        <p>Don't have an Account?</p>
        <a href="javascript:;" data-target="#modalRegisterForm" data-toggle="modal"><span data-dismiss="modal"
          aria-label="Close" #closeModal>SignUp</span></a>
      </div>
      </form>
    </div>
  </div>
</div>
<!-- Login modal ends-->

<!-- Signup modal starts -->
<div class="modal fade" id="modalRegisterForm" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <app-loader></app-loader>
      <!-- close button -->
      <button style="position: absolute; opacity: 0;" #closeSignupModal type="button" class="close" data-dismiss="modal"
              aria-label="Close" (click)="clearErrors()">
        <span aria-hidden="true">&times;</span>
      </button>
      <h2>Sign Up</h2>
      <div [formGroup]="signUpForm" (keyup.enter)="signupUser()" class="modal-body">
        <div class="md-form">
          <img class="inputTag" src="assets/images/user.png">
          <input placeholder="Your Name" formControlName="name" type="text" id="first-name" autocomplete="off"
                 class="form-control validate">
        </div>
        <div class="md-form">
          <img class="inputTag" src="assets/images/phone.png">
          <input placeholder="Your Phone number" formControlName="phone" type="text" id="phone" autocomplete="off"
                 class="form-control validate">
        </div>
        <div class="md-form">
          <img class="inputTag" src="assets/images/mail-black.png">
          <input placeholder="Your Email" formControlName="email" type="email" id="orangeForm-email" autocomplete="off"
                 class="form-control validate">
        </div>

        <div class="md-form">
          <img class="inputTag" src="assets/images/lock.png">
          <input placeholder="Your Password" formControlName="password" type="password" id="orangeForm-pass"
                 autocomplete="off" class="form-control validate">
        </div>
        <div class="form-check">
          <label class="form-check-label">
            <input type="checkbox" class="form-check-input" formControlName="acceptTos">I agree to the Terms of Service.
          </label>
          <div *ngIf="errorMsg && errorMsg.length > 0">
            <span class="error-Msg">{{errorMsg}}</span>
          </div>
        </div>
        <button (click)="signupUser()" class="btn signUp">Create an Account</button>
      </div>
      <div class="modal-footer d-flex justify-content-center">
        <p>Do you have an Account?</p>
        <a href="javascript:;" data-dismiss="modal" #closeSignupModal aria-label="Close"><span data-target="#modalLoginForm" (click)="openLogin()" data-toggle="modal">SignIn</span></a>
      </div>
    </div>
  </div>
</div>
<!-- Signup modal ends-->
