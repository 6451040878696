// my-loader.component.ts
import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {LoaderService} from '../../services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  loading: boolean;
  updateMode = false;

  constructor(private loaderService: LoaderService, private ref: ChangeDetectorRef) {

    this.loaderService.isLoading.subscribe((v) => {
      console.log("loader:", v);
      this.loading = v;
      if (this.updateMode)
        this.ref.detectChanges();
      this.updateMode = !this.updateMode;
    });

  }

  ngOnInit() {
  }

}
