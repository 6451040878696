// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	serviceUrls: {
		user: 'https://realestate-backend.dev.ziggletech.com/users/api/v1',
		leads: 'https://realestate-backend.dev.ziggletech.com/leads/api/v1',
		payments: 'https://realestate-backend.dev.ziggletech.com/payments/api/v1',
		// user: 'http://localhost:3001/api/v1',
		// leads: 'http://localhost:3003/api/v1',
		// payments: 'http://localhost:3002/api/v1'
	}
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
