import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {AuthService} from './auth.service';
import {Observable} from 'rxjs';
import {ToastrService} from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router, private toasterService: ToastrService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let subscription = localStorage.getItem('subscription')
    let isValidSubscription = true;
    const currentUrl = this.router.url;
    console.log("currentUrl", currentUrl);
    if (subscription) {
      subscription = JSON.parse(subscription);
    }

    // if subscription is not active
    if (!subscription) {
      console.log("1");
      isValidSubscription = false;
      this.toasterService.info("User does not have any active subscription, Please add/update payment method");
    } // @ts-ignore
    else if (subscription.isExpired) {
      console.log("2");
      isValidSubscription = false;
      this.toasterService.info("We are not able to renew your subscription, Please add/update payment method");
    }    // @ts-ignore
    else if (!(subscription.status.toLowerCase() === 'trialing' || subscription.status.toLowerCase() === 'active')) {
      // @ts-ignore
      console.log("3", subscription.status.toLowerCase());
      isValidSubscription = false;
      this.toasterService.info("Your subscription is not in active state");
    }
    if (currentUrl !== '/profile' && !isValidSubscription)
      this.router.navigateByUrl("/profile");
    return isValidSubscription;
  }

}
