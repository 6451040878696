<!-- Sidebar -->
<div class="DashboardHeader">
  <nav class="navbar navbar-expand-lg navbar-expand-lg">
    <div class="mobileHeader">
      <div class="hamburger">
        <i #toggleButton class="fa fa-bars" (click)="toggleNavBar()"></i>
      </div>
      <a class="navbar-brand MobLogo" (click)="navigateTo('dashboard')">
        <img src="assets/images/Logo.png" alt="logo">
        <!-- Real Estate Data -->
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
        <span class="navbar-toggler-icon"><i class="fa fa-search"></i></span>
      </button>
    </div>
    <div class="collapse navbar-collapse justify-content-end w-100" id="collapsibleNavbar">
      <ul class="navbar-nav d-flex justify-content-between align-items-center w-100">
        <li class="nav-item d-flex align-items-center webLogo">
          <div class="hamburger webLogo" style="display: inline-block;">
            <i #toggleButtonMob class="fa fa-bars" (click)="toggleNavBar()"></i>
          </div>
          <a class="navbar-brand" (click)="navigateTo('dashboard')" style="cursor: pointer;">
            <img src="assets/images/Logo.png" alt="logo">
          </a>
        </li>
        <li class="nav-item" *ngIf="currentTab === 'dashboard' || (user | json) != '{}'">
          <div class="search-box">
            <input type="text" class="form-control" id="search" (keyup.enter)="searchProperties()"
                   placeholder="Search by City, Country or Zipcode" [(ngModel)]="searchKeyword" list="autoCompleteList">
            <img src="../../../assets/images/dashboard/header/search.svg" alt="search" (click)="searchProperties()">
            <datalist id="autoCompleteList">
              <option *ngFor="let item of autoCompleteList" [value]="item.keyword">{{item.keyword}}</option>
            </datalist>
          </div>
        </li>
        <li class="nav-item" (click)="navigateTo('profile')">
          <div class="user">
            {{user.name}}
            <img src="../../../assets/images/dashboard/header/user.png" alt="user">
          </div>
        </li>
      </ul>
    </div>
  </nav>
</div>


<div #sideMenu id="wrapper" [class]="toggleNavbar">

  <!-- Sidebar -->
  <div id="sidebar-wrapper">
    <ul class="sidebar-list">
      <li [class.active]="currentTab === 'saved-leads'" class="list-item" (click)="navigateTo('saved-leads')">
        <img src="../../../assets/images/dashboard/sidebar/bookmark.png" class="unactive-img">
        <img src="../../../assets/images/dashboard/sidebar/blue-bookmark.png" class="active-img">
        Saved Leads
      </li>
      <li [class.active]="currentTab === 'search-history'" class="list-item" (click)="navigateTo('search-history')">
        <img src="../../../assets/images/dashboard/sidebar/heart.png" class="unactive-img">
        <img src="../../../assets/images/dashboard/sidebar/blue-heart.png" class="active-img">
        Search History
      </li>
      <li [class.active]="currentTab === 'subscriptions'" class="list-item" (click)="navigateTo('subscriptions')">
        <img src="../../../assets/images/dashboard/sidebar/shopping-bag.png" class="unactive-img">
        <img src="../../../assets/images/dashboard/sidebar/blue-bag.png" class="active-img">
        My Subscriptions
      </li>
      <li [class.active]="currentTab === 'profile'" class="list-item" (click)="navigateTo('profile')">
        <img src="../../../assets/images/dashboard/sidebar/user.png" class="unactive-img">
        <img src="../../../assets/images/dashboard/sidebar/blue-user.png" class="active-img">
        My Profile
      </li>
      <li [class.active]="currentTab === 'settings'" *ngIf="user.role === 'Admin'" class="list-item"
          (click)="navigateTo('settings')">
        <img src="../../../assets/images/dashboard/sidebar/settings.png" class="unactive-img">
        <img src="../../../assets/images/dashboard/sidebar/blue-settings.png" class="active-img">
        Global Setting
      </li>
      <li [class.active]="currentTab === 'users'" *ngIf="user.role === 'Admin'" class="list-item"
          (click)="navigateTo('users')">
        <img src="../../../assets/images/dashboard/sidebar/search.png" class="unactive-img">
        <img src="../../../assets/images/dashboard/sidebar/blue-search.png" class="active-img">
        Search User
      </li>
      <li [class.active]="currentTab === 'survey-action'" *ngIf="user.role === 'Admin'" class="list-item"
          (click)="navigateTo('survey-action')">
        <img src="../../../assets/images/dashboard/sidebar/survey.png" class="unactive-img">
        <img src="../../../assets/images/dashboard/sidebar/blue-survey.png" class="active-img">
        Lead Survey Action
      </li>
      <li class="list-item" (click)="logoutUser()">
        <img src="../../../assets/images/dashboard/sidebar/logout.png" class="unactive-img">
        <img src="../../../assets/images/dashboard/sidebar/blue-logout.png" class="active-img">
        Logout
      </li>
    </ul>

  </div>

</div>
