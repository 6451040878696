import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AdminService} from "../../../services/admin.service";
import {ToastrService} from "ngx-toastr";
import {SearchService} from "../../../services/search.service";
import {HelperService} from "../../../services/helper.service";
import { CellButtonsComponent } from '../../../shared/components/cell-buttons/cell-buttons.component';
  import {Location} from '@angular/common'; 
  import {Router} from "@angular/router";

@Component({
  selector: 'app-search-user',
  templateUrl: './search-user.component.html',
  styleUrls: ['./search-user.component.scss']
})
export class SearchUserComponent implements OnInit {
  searchText = '';
  users: any = [];
  currentUser: {
    name: string, email: string, status: string, userId: string, phone: string, paymentAmount: string,
    threshold: string, zipCode: string, city: string, county: string, role: string
  };
  updateIndex: number;
  editUser:any;
  userHistory:any;
  searchUserHistory=false;

  // ag-table
  private gridApi: any;
  private gridColumnApi: any;
  defaultColumnDefs = {
    filter: true,
    sortable: true,
    filterParams: {
      buttons: ["apply", "clear", "cancel"],
      closeOnApply: true,
    },
    resizable: true,
    draggable: true
  }
  rowStyles = this.helperService.getRowStyles();
  columnDefs = [
    {headerName: 'Action', field: 'userId', cellRendererFramework:  CellButtonsComponent},
    {headerName: "Name", field: 'name'},
    {headerName: 'Email', field: 'email'},
    {headerName: 'Status', field: 'status'},
    {headerName: 'Phone', field: 'phone'},
    {headerName: 'Payment Amount', field: 'paymentAmount'},
    {headerName: 'Threshold', field: 'threshold'},
    {headerName: 'Zip Code', field: 'zipCode'},
    {headerName: 'City', field: 'city'},
    {headerName: 'County', field: 'county'},
    {headerName: 'Role', field: 'role'}

  ];

  constructor(private adminService: AdminService, private toaster: ToastrService,
              private helperService: HelperService,private location: Location,private searchService: SearchService,private router: Router) {
  }

  ngOnInit(): void {
    this.searchUsers();
    document.removeEventListener('editUser', this.getUserDetails, false);
    document.addEventListener('editUser', (e)=>{this.getUserDetails(e)}, false);
      document.removeEventListener('searchUserHistory', this.getUserDetails, false);
    document.addEventListener('searchUserHistory', (e)=>{this.getUserHistory(e)}, false);
  }

  searchUsers() {
    console.log("Searching users", this.searchText)
    this.editUser=false;
    this.searchUserHistory=false;
    // if (this.searchText.length === 0) {
    //   this.toaster.error("Please enter search text")
    //   return 0;
    // }
    localStorage.setItem('searchUser', this.searchText);
    // if(this.searchText!==''){
    //   localStorage.setItem('searchUser', this.searchText);
    // }
    
    let searchUser = localStorage.getItem('searchUser');
    if(searchUser){
      this.searchText=searchUser;
    }
       this.adminService.searchUsers(this.searchText ).subscribe((response) => {
      if (response.data) {
        this.users = response.data;
      } else {
        this.toaster.info(response.status.message);
      }
    });
    return 1;
  }

  onGridReady(params: { api: any; columnApi: any; }) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  getUserDetails(e:any) {
    const userId = e.detail
    const user = this.users.find((x:any) => x.userId === userId);
    this.editUser=true;
    this.currentUser =  {...user};
    const updateIndex = this.users.indexOf(user);
    this.updateIndex = updateIndex;
      this.location.replaceState(`users/edit?userId=${this.currentUser.userId}`);
  }
    getUserHistory(e:any) {
    let UserId = e.detail
    const user = this.users.find((x:any) => x.userId === UserId);
    this.currentUser =  {...user};
    this.searchService.userSearchHistory(UserId).subscribe((response: any) => {
       this.searchUserHistory=true;
       if((response.data).length>0){
        this.userHistory= response.data 
       }
       else{
         this.userHistory=0;
       }
      
    this.location.replaceState(`users/history?userId=${this.currentUser.userId}`);
    })
  
  }

  updateUser() {
    let updateObject = {userId: this.currentUser.userId}
    Object.keys(this.currentUser).forEach((key) => {
      // @ts-ignore
      if (this.currentUser[key] !== this.users[this.updateIndex][key]) { // @ts-ignore
        updateObject[key] = this.currentUser[key];
      }
    })
    this.adminService.updateUser(updateObject).subscribe((response) => {
      if (response.data) {
        // location.reload();
        window.location.replace(`users`);
        this.gridApi.getSelectedNodes()[0].setData({...this.currentUser});
        this.users[this.updateIndex] = {...this.currentUser};
        this.toaster.info("User Updated Successfully");
      } else
        this.toaster.error("Error in updating user, Please try again");
    })
    this.editUser=false;
  }
    searchById(data: any) {
      console.log(data)
    console.log("searched", data);
    const routeData = { keyword: data.searchText, type: data.type };
    // @ts-ignore
    this.router.navigate(['dashboard'], routeData)
  }

  clearSelection() {
    this.gridApi.deselectAll();
  }
  exit() {
     localStorage.removeItem('searchUser');
     this.editUser=false;
  }
  removeSearch(){
    localStorage.removeItem('searchUser');
  }

  // to go back to search user from history page

  backHistory(){
    this.searchUserHistory=false;
  }

}
