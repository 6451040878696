<div class="table" [class]="split? 'half': 'full'">
  
  <div class="sub-header">
    <div class="">
      <h2 *ngIf="leads && leads.length >0"><span
        *ngIf="selectedLeads && selectedLeads.length >0">Selected {{selectedLeads.length}}</span>
        Leads in {{ LeadsInCity}}
      </h2>
    </div>
    <div class="align">
      <!-- <select2
        [data]="leadColOptions"
        [multiple]="true"
        [placeholder]="'Select an Option'"  
        [(ngModel)]="selectedValue"
        (update)="dropdown()">
      </select2> -->
      <div class="d-flex align-items-center">
        <div *ngIf="selectedLeads && selectedLeads.length > 0" class="d-flex align-items-center ml-lg-2 ml-0">
          <a (click)="saveLeads()" class="btn actions-btn" data-toggle="tooltip" data-placement="top" title="Save">
            <img src="assets/images/dashboard/sub-header/Save.png">
            <span class="textHidden">Save</span>
          </a>
          <a (click)="sendLeadsEmail()" class="btn actions-btn" data-toggle="tooltip" data-placement="top" title="Email">
            <img src="assets/images/dashboard/sub-header/mail.png">
            <span class="textHidden">Email</span>
          </a>
          <a (click)="downloadPdf()" class="btn actions-btn" data-toggle="tooltip" data-placement="top" title="Download">
            <img src="assets/images/dashboard/sub-header/download.png">
            <span class="textHidden">Download</span>
          </a>
        </div>
        <button class="btn actions-btn columnBtn" data-toggle="modal" data-target="#modal-filter" >
          <img src="assets/images/column.png" data-toggle="tooltip" data-placement="top" title="Columns">
          <span class="textHidden" data-toggle="tooltip" data-placement="top" title="Columns">Columns</span>
        </button>
      </div>
      <!--  switch dashboard view controls-->
      <div class="map-view-control ml-lg-3 ml-0" [style.display]="split? 'none': 'flex'">
        <div class="view-tabs" [ngClass]="dashboardView==='list'? 'active': ''"
             (click)="changeDashboardView('list')">
          <img src="../../../../assets/images/dashboard/pause-tab.png">
        </div>
        <div class="view-tabs hideTab" [ngClass]="dashboardView==='hybrid'? 'active': ''"
             (click)="changeDashboardView('hybrid')">
          <img src="../../../../assets/images/dashboard/column-tab.png">
        </div>
        <div class="view-tabs" [ngClass]="dashboardView==='map'? 'active': ''"
             (click)="changeDashboardView('map')">
          <img src="../../../../assets/images/dashboard/map-tab.png">
        </div>
      </div>
      
    </div>
  </div>
  <div>
    <app-loader></app-loader>
    <ag-grid-angular
      class="ag-theme-material"
      [rowData]="rowData"
      [rowStyle]="rowStyles"
      [defaultColDef]="defaultColumnDefs"
      [columnDefs]="columnDefs"
      rowSelection="multiple"
      [suppressRowClickSelection]=true
      [suppressCellSelection]=true
      (gridReady)="onGridReady($event)"
      (selectionChanged)="onSelectionChanged()"
      (rowClicked)="LoadLeadDetails($event)"
    >
    </ag-grid-angular>
  </div>
</div>


<!-- The Modal -->
<div class="modal fade" id="modal-filter">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
    
      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Customize Columns</h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>
      
      <!-- Modal body -->
    <form [formGroup]="sortingLeads">
      <div class="modal-body">
        <!-- use this (cdkDropListDropped)="drop($event)" -->
        <div cdkDropList class="list">
          <div class="box" *ngFor="let movie of leadColOptions;let i = index" formArrayName="leadSort" cdkDrag>
            <!-- <div class="custom-placeholder" *cdkDragPlaceholder></div> -->
            <div class="form-check">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input mr-3" [formControlName]="i">{{movie.label}}
              </label>
            </div>
          </div>
        </div>
      
      </div>
      
      <!-- Modal footer -->
      <div class="modal-footer">
        <button type="button" class="btn cancelBtn" data-dismiss="modal">Cancel</button>
        <button type="button" (click)="sortLeads();" data-dismiss="modal" class="btn saveBtn">Confirm</button>
      </div>
    </form>
    </div>
  </div>
</div>