import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() { }
  public getToken(): string {
    return <string>localStorage.getItem('token');
  }

  public logout(): void {
    return <void>localStorage.removeItem('token');
  }
}
