import {ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {UserService} from "../../services/user.service";
import {Router} from '@angular/router';
import {FormBuilder,FormControl, FormGroup, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {OwlOptions} from 'ngx-owl-carousel-o';
import { DOCUMENT } from '@angular/common';
declare var $: any;

@Component({
  selector: 'app-map-component',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  errorMsg = '';
    forgetPasswordForm!: FormGroup;
    subscribeForm!: FormGroup;
    contactUsForm!: FormGroup;
  loginForm = new FormGroup({
    email: new FormControl(undefined, [Validators.email, Validators.required]),
    password: new FormControl(undefined, [Validators.minLength(8), Validators.required])
  });
  signUpForm = new FormGroup({
    name: new FormControl('', Validators.required),
    phone: new FormControl('', Validators.required),
    email: new FormControl('', Validators.email),
    password: new FormControl('', Validators.minLength(8)),
    acceptTos: new FormControl(false)
  });
  isSignup = false;
  @ViewChild('closeModal') closeModal: ElementRef
  @ViewChild('closeSignupModal') closeSignupModal: ElementRef

  public forgetPassword: boolean = false;


  constructor(private userService: UserService, private router: Router, private toaster: ToastrService,
              private ref: ChangeDetectorRef,private formbuilder: FormBuilder, @Inject(DOCUMENT) private document: Document,) {
    this.loginForm = new FormGroup({
      email: new FormControl('', Validators.email),
      password: new FormControl('', Validators.minLength(8))
    });
  }

  ngOnInit(): void {
    this.document.body.classList.remove('body-overflow');
    
       this.forgetPasswordForm = this.formbuilder.group({
        email:[""],
      });
    this.contactUsForm = this.formbuilder.group({
        name:[""],
        email:[""],
        message:[""],
      });
    this.subscribeForm = this.formbuilder.group({
        email:[""],
      });
    this.userService.isUserLoggedIn.subscribe((isUserLoggedIn) => {
      if (isUserLoggedIn) {
        this.getSubscription();
      }
    })
    
  }

  // init login for user
  loginUser() {
    this.errorMsg = '';
    if (!this.loginForm.valid || this.loginForm.value.email.length === 0 || this.loginForm.value.password.length === 0) {
      this.errorMsg = "Make sure email is valid and password should be at least 8 char long";
      console.log("Error state")
      return 0;
    } else {
      let data = this.loginForm.value;
      console.log("data from login form is", data);
      this.userService.loginUser(data).subscribe((response) => {
        const token = response.headers.get("Authorization");
        if (token) {
          this.userService.setUser(response.body.data);
          this.closeModal.nativeElement.click();
          localStorage.setItem("token", token);
          this.userService.updateLoginStatus(true);
        } else {
          console.log("Login error:", response.body.status.message);
          this.errorMsg = response.body.status.message;
        }
      });
      return 1;
    }
  }

  // init signup for user
  signupUser() {
    this.errorMsg = '';
    if (!this.signUpForm.valid) {
      this.errorMsg = "Make sure all the fields are filled";
      return 0;
    } else {
      if (!this.signUpForm.value.acceptTos) {
        this.errorMsg = "Please accept TOS!";
        return 0
      }
      let signupData = this.signUpForm.value;
      delete signupData.acceptTos;
      console.log("data from login form is", signupData);
      this.userService.signupUser(signupData).subscribe((response) => {
        this.userService.setUser(response.body.data)
        if (response.body.data) {
          this.isSignup = true;
          this.closeSignupModal.nativeElement.click();
          const token = response.headers.get("Authorization");
          localStorage.setItem("token", token);
          this.userService.updateLoginStatus(true);
          this.router.navigate(['/profile']);
        } else {
          this.errorMsg = response.body.status.message;
          this.ref.detectChanges();
        }
      });
      return 1;
    }
  }

  clearErrors() {
    this.errorMsg = '';
  }

  // get user subscription
  getSubscription() {
    this.userService.getUserSubscription().subscribe((response) => {
      const data = response.data;
      if (data) {
        localStorage.setItem('subscription', JSON.stringify(data));
        this.router.navigate(['/dashboard']);
      } else if (this.isSignup) {
        this.toaster.info("Please add a payment method!")
      } else {
        this.router.navigate(['/profile']);
        this.toaster.info(response.status.message)
      }
    })
  }

  // for carousel

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['<img alt="arrow" src="assets/images/arrow-left.svg" class="owl-prev">', '<img alt="arrow" src="assets/images/arrow-right.svg" class="owl-next">'],
    responsive: {
      0: {
        items: 2.5
      },
      400: {
        items: 4
      },
      740: {
        items: 3
      },
      940: {
        items: 5
      },
      1442: {
        items: 6
      }
    },
    nav: true
  }

  // show hide forget password
  forgetShow() {
    this.forgetPassword = !this.forgetPassword;
  }
  openLogin(){
    this.forgetPassword = false; 
    this.clearErrors() 
  }
  
  postForgetPassword(){
     let email=this.forgetPasswordForm.get('email')?.value ;
     this.userService.forgetPassword({'email':email}).subscribe((Response)=>{
      if(Response.status.code===200){
      this.toaster.success('Reset password link sent successfully to your registered email id')
       this.closeModal.nativeElement.click();  // set his when api is called
       this.forgetPasswordForm.reset();
      }else{
        this.toaster.error(Response.status.message)
      }
       
     })

  }
  subscribeSubmit(){
    let email=this.subscribeForm.get('email')?.value;
    if(email===''){
      this.toaster.info('Please enter email to subscribe')
    }
    else{
       this.userService.subscribeEmail({'email':email}).subscribe((Response)=>{
         this.toaster.success('Thank you for subscribing');
         this.subscribeForm.reset();
     })
    }
  }
  contactSubmit(){
     let email=this.contactUsForm.get('email')?.value;
     let name=this.contactUsForm.get('name')?.value;
     let message=this.contactUsForm.get('message')?.value;
     console.log(message)
    if(email==='' && message===''){
      this.toaster.info('Please enter email and message')
    }
    else if(email===''){
      this.toaster.info('Please enter your email')
    }
    else if(message===''){
      this.toaster.info('Please enter message');
    }
    else{
         this.userService.subscribeEmail({'email':email,'name':name,'message':message}).subscribe((Response)=>{
           this.toaster.success('Thank you for contacting us')
           this.contactUsForm.reset();
     })
    }
  }
}
