<div class="filter" *ngIf="showFilters">
  <div class="modal-content">
    <div class="modal-header">
      <!-- filters button  -->
      <div class="filter-part">
        <img src="../../../../assets/images/dashboard/header/filters.png">
        <span>Filters</span>
      </div>
      <!-- filter tabs -->
      <ul class="nav nav-pills">
        <li class="nav-item">
          <a class="nav-link active" (click)="toggleActiveTab('current')" data-toggle="pill" href="#current">Current
            Filters</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="toggleActiveTab('saved')" data-toggle="pill" href="#saved">Saved Filters</a>
        </li>
      </ul>
      <!-- close button -->
      <button (click)="showFiltersTab(false)" type="button" class="close">
        <span aria-hidden="true">Close <img src="/assets/images/property-view/cross.svg" alt="close"></span>
      </button>
    </div>

    <div class="modal-body">
      <!-- tabs-content -->
      <div class="tab-content">
        <!-- current filters -->
        <div class="tab-pane active" id="current">
          <div class="container">
            <div class="currentfilters">
              <!-- motivation buttons -->
              <div class="parts" *ngFor="let filter of filtersData">
                <h2>{{filter.label}}</h2>
                <div *ngIf="filter.type === 'flag'" class="category-part">
                  <button *ngFor="let item of filter.data" class="btn category-btn checked"
                          (click)="selectFilter(item.name, true, 'flag', filter.name)">
                    <img *ngIf="item.icon" src="assets/images/{{item.icon}}">
                    <p class="name">{{item.label}}</p>
                    <img *ngIf="item.value" src="assets/images/full-check.svg" class="mr-0">
                  </button>
                </div>
                <div *ngIf="filter.type === 'range' && filter.name != 'yearBuilt'" class="price-input">
                  <div class="form-group ml-0">
                    <select class="form-control" [(ngModel)]="filter.values.min" (change)="rangeLimit(filter.values.min, filter.data)"
                            (change)="selectFilter(filter.name, '', 'range', 'min' )">
                      <option [selected]="!filter.values.min" selected>No min limit</option>
                      <option *ngFor="let range of filter.data" [selected]="filter.values.max === range"
                              [value]="range">{{range}}
                      </option>
                    </select>
                  </div>
                  <p class="mb-0">-</p>
                  <div class="form-group">
                    <select class="form-control" [(ngModel)]="filter.values.max"
                            (change)="selectFilter(filter.name, '', 'range', 'max' )">
                      <option [selected]="!filter.values.max" selected>No max limit</option>
                      <option *ngFor="let range of maxRange" [selected]="filter.values.max === range"
                              [value]="range">{{range}}
                      </option>
                    </select>
                  </div>
                </div>
                <div *ngIf="filter.type === 'range' && filter.name === 'yearBuilt'" class="price-input">
                  <div class="form-group ml-0">
                    <input type="number" class="form-control"  id="min"  [(ngModel)]="filter.values.min" placeholder="Start Year" (keydown.enter)="minYear($event)" (focusout)="minYear($event)">
                  </div>
                  <p class="mb-0">-</p>
                  <div class="form-group">
                    <input type="number" class="form-control" id="max"  [(ngModel)]="filter.values.max" placeholder="End Year" (keydown.enter)="maxYear($event)" (focusout)="maxYear($event)" >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- saved filters -->
        <div class="tab-pane fade" id="saved">
          <div class="container">
            <div class="savedFilters">
              <div class="sortList">
                <h2>Saved Filters</h2>
              </div>
              <!-- saved cards starts here -->
              <div class="savedCard" *ngFor="let data of savedFilters; let i=index;" style="cursor: default;">
                   <!-- <i data-toggle="modal" data-target="#delCard" class="fa fa-times close"></i> -->
                <div class="text">
                  <h4>{{data.name}}</h4>
                  <p>
                    <span *ngFor="let famFilter of data.filters[2].data">
                      <span *ngIf="famFilter.value">
                        {{famFilter.label}},&nbsp;
                      </span>
                    </span>
                    <span>{{data.filters[1].values.min}} - {{data.filters[1].values.max}}</span>
                  </p>
                </div>
                <div class="area">
                  <img src="assets/images/dashboard/sub-header/ruler.png" alt="ruler">
                  <p>{{data.filters[3].values.min}} - {{data.filters[3].values.max}}</p>
                </div>
                <div class="day">
                  <p>{{data.date}}</p>
                </div>
                <div class="motivations">
                  <span *ngFor="let motivFilter of data.filters[0].data; let i = index;">
                    <img *ngIf="motivSavedFilterHelper(motivFilter, data.filters[0].data.length, i) === 'list'"
                         alt="motivation" src="assets/images/{{motivFilter.icon}}">
                  </span>
                </div>
                <div class="moreOption">
                  <div class="dropdown dropright float-right">
                    <a class="more-items" data-toggle="dropdown">
                      <img src="assets/images/dashboard/sub-header/more-dots.png" alt="more-options">
                    </a>
                    <div class="dropdown-menu">
                      <a class="dropdown-item" href="javascript:;" (click)="applySavedFilter(i)">Apply Filters</a>
                      <a class="dropdown-item" data-toggle="modal" attr.data-target="#delcard{{data.id}}" data-backdrop="false">Delete Filters</a>
                    </div>
                  </div>
                </div>

                <!-- deleting the cards modal data-toggle="modal" data-target="#delCard" -->
                  <div class="modal" id="delcard{{data.id}}">
                    <div class="modal-dialog delmodal modal-dialog-centered">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h4 class="modal-title">Are you sure you want to delete this card?</h4>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn" (click)="deleteSaveFilters(data.id)" data-dismiss="modal">Yes</button>
                          <button type="button" class="btn delBtn" data-dismiss="modal">No</button>
                        </div>

                      </div>
                    </div>
                  </div>
              </div>
              <!-- saved cards end here -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer" *ngIf="activeTab==='current'">
      <div class="container">
        <div class="footer-btn">
          <a (click)="resetFilters()" style="cursor: pointer;">Reset Filters</a>
          <div class="btn-grp">
            <button (click)="applyFilters()" type="button" class="btn show mr-lg-3 mr-1">Show {{resultsCount}} result
            </button>
            <input class="show mr-lg-3 mr-1" type="text" [(ngModel)]="filterName" placeholder="Saved filters label">
            <button (click)="saveSelectedFilters()" type="button" class="btn save">Save Filters</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


