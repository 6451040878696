import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  baseUrl = environment.serviceUrls.user;

  constructor(private http: HttpClient) {
  }

  public searchUsers(searchText: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/admin/users?searchText=${searchText}`);
  }

  public updateUser(updateOpts: any): Observable<any> {
    return this.http.put(`${this.baseUrl}/admin/user`, updateOpts);
  }

  public getAllSettings(): Observable<any> {
    return this.http.get(`${this.baseUrl}/admin/settings`);
  }

  public addSetting(opts: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/admin/settings`, opts);
  }
}
