import {Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {SearchService} from "../../services/search.service";
import {UserService} from "../../services/user.service";
import { Location } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @ViewChild('toggleButton') toggleButton: ElementRef;
  @ViewChild('toggleButtonMob') toggleButtonMob: ElementRef;
  @ViewChild('sideMenu') menu: ElementRef;
  toggleNavbar = '';
  searchKeyword: string;
   searchType: string;
  user: { city: string, role: string, threshold: string, name: string };
  autoCompleteList: any;
  currentTab = '';
  searchHeader:any;

  constructor(private router: Router, private searchService: SearchService,
              private userService: UserService, private renderer: Renderer2,private Location:Location) {
    const path = window.location.href.split('/');
    this.currentTab = path[path.length-1];
    this.renderer.listen('window', 'click', (e: Event) => {
      if (e.target !== this.toggleButton.nativeElement && e.target !== this.toggleButtonMob.nativeElement &&
        e.target !== this.menu.nativeElement) {
        this.toggleNavbar = '';
      }
    });
  }

  ngOnInit(): void {
    console.log("header"+this.router.url)
    // @ts-ignore
    this.user = this.userService.getUser();
    this.searchService.getSearchKeywords().subscribe((data) => {
      this.autoCompleteList = data.data;
    });
    this.searchService.getSearchData().subscribe((data) => {
      if(this.router.url==='/dashboard'){
        this.currentTab='dashboard'
      }
      console.log("header got data from service", data);
      console.log(data)
      this.searchKeyword = data.keyword;
      this.searchType=data.type;
    })
    
  }

  toggleNavBar() {
    if (this.toggleNavbar === 'active')
      this.toggleNavbar = '';
    else
      this.toggleNavbar = 'active';
  }

  searchProperties() {   
    this.searchService.searchDetails = this.getSearchDataByKeyword();
   setTimeout(()=>{ 
    let option={"searchText":this.searchKeyword,"searchType":this.searchType};
    if(this.searchKeyword!==null){
       localStorage.setItem('search',JSON.stringify(option))
    }
    this.searchService.postSearchHistory(option).subscribe((data) => {
      console.log(data)
    });
   }, 1000);
   
    console.log("Search triggered", this.searchKeyword);
    this.searchService.sendSearchData();
    if (this.router.url !== '/dashboard')
      this.router.navigate(['/dashboard'])

        setTimeout(function(){
    
  }, 1000);
  }

  navigateTo(path: string) {
     if(this.searchService.searchHeaderData){
        this.searchHeader=true;
      }else{
        this.searchHeader=false;
      }
    this.currentTab = path;
    if (this.router.url !== `/${path}`)
      this.router.navigate([`/${path}`]);
    // nav bar should be closed once user click on an item
    if (this.toggleNavbar === 'active')
      this.toggleNavBar();
  }

  private getSearchDataByKeyword() {
    return this.autoCompleteList.find((data: { keyword: string; }) => data.keyword === this.searchKeyword)
  }

  public logoutUser() {
    localStorage.clear()
    this.userService.updateLoginStatus(false);

  }
}