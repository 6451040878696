import { Component, OnInit } from '@angular/core';
import {SearchService} from "../../../services/search.service";
 import {Location} from '@angular/common'; 

@Component({
  selector: 'app-cell-buttons',
  templateUrl: './cell-buttons.component.html',
  styleUrls: ['./cell-buttons.component.scss']
})
export class CellButtonsComponent implements OnInit {
 
  userId:any
  constructor(private searchService: SearchService,private location: Location) { }

  agInit(params:any){
    this.userId = params.value;
  }

  ngOnInit(): void {
  }

  edit(){
    document.dispatchEvent( new CustomEvent('editUser', {detail:this.userId}));
  }
  searchHistory(){
    console.log("hii"+this.userId)
       document.dispatchEvent( new CustomEvent('searchUserHistory', {detail:this.userId}));
  }

}
