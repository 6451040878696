<div class="profile">
  <div class="container">
    <div class="profile-details">
      <app-loader></app-loader>
        <div class="d-flex justify-content-between align-items-center">
          <h2>Profile</h2>
          <button (click)="postData()" class="btn save">Save</button>
        </div>
      <div class="email">
        <div class="w-100">
          <div class="input mt-2">
            <div [ngClass]="editMode === 'email'? 'ag-hidden': ''">
              <label>Email</label>
              <p>{{profileDetails.email}}</p>
            </div>
            <div *ngIf="editMode === 'email'" class="form-group">
              <label for="email">Email</label>
              <input [(ngModel)]="profileDetails.email" type="text" class="form-control" id="email"
                     placeholder="Enter New Email" (keydown.enter)="updateUser('email')">
            </div>
            <a *ngIf="editMode !== 'email'" (click)="enableEditMode('email')">Edit</a>
          </div>
          <div class="input">
            <div>
              <label>Password</label>
              <p>**************</p>
            </div>
            <a *ngIf="editMode !== 'password'" data-toggle="modal" data-target="#myModal" class="mb-1">Change</a>
          </div>
        </div>
        <!--        <div class="user">-->
        <!--          <img src="assets/images/dashboard/header/user.png" alt="user">-->
        <!--          <a>Edit</a>-->
        <!--        </div>-->
      </div>
      <form [formGroup] ="profile">
      <div class="otherDetails">
        <div class="form-group">
          <label for="name">Full name</label>
          <input  type="text" class="form-control" formControlName = "name" id="name"
                 placeholder="User Full Name" value="{{profileDetails.name}}">
        </div>
        <div class="form-group">
          <label for="phone">Phone number</label>
          <input type="number" class="form-control" formControlName = "phone" id="phone"
                 placeholder="User Phone number" value="{{profileDetails.phone}}">
        </div>
        <!-- <div class="form-group">
          <label for="country">Country</label>
          <input type="text" class="form-control" formControlName = "country" id="country"
                 placeholder="User Country" value="{{profileDetails.county}}">
        </div> -->
        <div class="form-group">
          <label for="phone">City</label>
          <input type="text" class="form-control" formControlName = "city" id="city"
                 placeholder="User City" value="{{profileDetails.city}}">
        </div>
        <div class="form-group">
          <label for="zipcode">Zip Code</label>
          <input type="number" class="form-control" formControlName = "zipcode" id="zipcode"
                 placeholder="User ZipCode" value="{{profileDetails.zipCode}}">
        </div>
        <!-- <div class="form-group">
          <label for="usertype">User type</label>
          <select class="form-control" id="usertype">
            <option>{{profileDetails.role}}</option>
          </select>
        </div> -->
        <div class="form-group">
          <label for="paymentDetails" class="payment">Payment Details
            <!-- <ng-container> -->
              <a *ngIf="editMode !== 'paymentMethod'" (click)="enableEditMode('paymentMethod')"
                class="ml-2">+Add
              </a>
            <!-- </ng-container> -->
          </label>
          <div class="paymentCard" *ngFor="let paymentMethod of paymentMethods">
            <span>XXXX XXXX XXXX {{paymentMethod.card.last4}}</span>
            <span>{{paymentMethod.card.exp_month}}/{{paymentMethod.card.exp_year}}</span>
            <img src="assets/images/green-check-full.png" alt="selected" class="default" *ngIf="paymentMethod.isDefault">
           <i data-toggle="modal" attr.data-target="#delCard{{paymentMethod.id}}" data-backdrop="false" class="fa fa-times-circle close">
           </i>
              <!-- deleting the cards modal -->
                <div class="modal" id="delCard{{paymentMethod.id}}">
                  <div class="modal-dialog delmodal modal-dialog-centered"  >
                    <div class="modal-content">
                      <div class="modal-header">
                        <h4 class="modal-title">Are you sure you want to delete this card?</h4>
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn" (click)="deletePaymentMethods(paymentMethod.id,paymentMethod.isDefault)" data-dismiss="modal">Yes</button>
                        <button type="button" class="btn delBtn" data-dismiss="modal">No</button>
                      </div>

                    </div>
                  </div>
                </div>
          </div>
          <div *ngIf="numberOfPaymentProfiles === 0">
            <p>Please add a payment method before activating a subscription, You
            won't be charged for a month and you can cancel subscription at any time.</p>
          </div>
          <!-- <ng-container *ngIf="cancelCard !== 'paymentCancel'"> -->
            <div class="addPaymentCard" *ngIf="editMode==='paymentMethod'">
              <div class="addPayment" >
                <ngx-stripe-card-group [elementsOptions]="elementsOptions">
                  <ngx-stripe-card id="paymentDetails" [options]="cardOptions"></ngx-stripe-card>
                </ngx-stripe-card-group>
              </div>
              <div class="btn-grp">
                <button (click)="addPaymentMethod()" class="btn addbtn">Add Your Card</button>
                <button (click)="enableEditMode('paymentCancel')" class="btn addbtn delbtn">Cancel</button>
              </div>
            </div>
          <!-- </ng-container> -->
        </div>

        <!-- <div class="form-group">
          <label for="usertype" class="payment">Subscription</label>
          <div class="subscriptionCard">
            <p>Monthly - $19.99</p>
            <p class="active">Active</p>
          </div>
        </div> -->

      </div>
      </form>
    </div>
  </div>
</div>


<!-- password modal -->
<!-- The Modal -->
<div class="modal fade" id="myModal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Change Password</h4>
        <button #closePasswordModal type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <div [formGroup]="passwordChangeForm" class="passwords">
          <div class="form-group">
            <label for="cpwd">Current Password</label>
            <input formControlName="oldPassword" type="password" class="form-control" id="cpwd"
                   placeholder="Current Password" autocomplete="new-password">
          </div>
          <div class="form-group">
            <label for="npwd">New Password</label>
            <input formControlName="newPassword" type="password" class="form-control" id="npwd"
                   placeholder="New Password">
          </div>
          <div class="form-group">
            <label for="conpwd">Confirm New Password</label>
            <input formControlName="confirmNewPassword" type="password" class="form-control" id="conpwd"
                   placeholder="Confirm New Password">
          </div>
        </div>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer">
        <button (click)="changePassword()" type="button" class="btn confirm w-100">Confirm</button>
      </div>

    </div>
  </div>
</div>


