import {Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, ViewChild, ElementRef, Inject,PLATFORM_ID,ApplicationRef} from '@angular/core';
import {LeadsService} from "../../../services/leads.service";
import {ToastrService} from "ngx-toastr";
import {jsPDF} from "jspdf";
import html2canvas from 'html2canvas';
import {HelperService} from "../../../services/helper.service";
import {LoaderService} from "../../../services/loader.service";
import {FormControl, FormGroup} from "@angular/forms";
import {UserService} from "../../../services/user.service";
import { DOCUMENT } from '@angular/common';
declare var $: any;
import {
   MapsAPILoader
} from '@agm/core';
import {
   isPlatformBrowser
} from '@angular/common';

const apiKey = 'AIzaSyAVEd6nJTndx8-Xqay8l8J-UXumbHAN0AQ'

@Component({
  selector: 'lead-details-view',
  templateUrl: './lead-details.component.html',
  styleUrls: ['./lead-details.component.scss']
})
export class LeadDetailsComponent implements OnInit {

  @ViewChild('leadImage') leadImage: ElementRef
  @ViewChild('leadDetailsButton') leadDetailsButton: ElementRef
  @ViewChild('savedInfoButton') savedInfoButton: ElementRef
  @ViewChild('closeSurveyModal') closeSurveyModal: ElementRef
  @ViewChild('streetviewMap') streetviewMap: ElementRef;
  @ViewChild('streetviewPano') streetviewPano: ElementRef;

  @Input() zoom: number = 11;
  @Input() heading: number = 34;
  @Input() pitch: number = 10;
  @Input() scrollwheel: boolean = false;

  leadDetails: any;
  images = [''];
  apiKey = apiKey;
  savedLeadData: { tags: any, notes: any, isSaved: boolean };
  showLeadData = 'property-view-close';
  editMode = ''
  tag = ''
  note = ''
  pdfDownloadInProgress = false;
  searchKeyword = '';
  surveyForm = new FormGroup({
    leadId: new FormControl(),
    purchased: new FormControl(false),
    closed: new FormControl(false)
  })

  // should be triggered on dashboard view change
  @Input('leadDetails') set setLeadDetails(leadDetails: any) {
    this.leadDetails = leadDetails.lead;
    this.surveyForm.controls['leadId'].setValue(this.leadDetails.id);
    this.getLeadImages();
    this.savedLeadData = leadDetails.savedData || {tags: [], notes: []};
    this.savedLeadData.tags = this.savedLeadData.tags && this.savedLeadData.tags.length > 0 ? this.savedLeadData.tags.split("~~") : [];
    this.savedLeadData.notes = this.savedLeadData.notes && this.savedLeadData.notes.length > 0 ? this.savedLeadData.notes.split("~~") : [];

    this.showLeadData = '';
  }

  @Input('searchText') set searchText(searchText: string) {
    this.searchKeyword = searchText;
  }

  // to send close lead details flag back
  @Output() showLeadDetails = new EventEmitter<boolean>(false);

  constructor(private ref: ChangeDetectorRef, private leadsService: LeadsService,
              private toasterService: ToastrService, private helperService: HelperService,
              private loaderService: LoaderService, private userService: UserService, @Inject(DOCUMENT) private document: Document,
              private toaster: ToastrService,
              @Inject(PLATFORM_ID) private platformId: Object,
              private mapsAPILoader: MapsAPILoader,
              private appRef: ApplicationRef) {
  }

  ngOnInit(): void {
    this.document.body.classList.add('body-overflow');
     this.renderStreetView();
  }

 renderStreetView() {
    if (isPlatformBrowser(this.platformId)) {
      this.mapsAPILoader.load().then(() => {
        const center = { lat: +this.images[0], lng: +this.images[1] };
        console.log(center)
        const map = new window['google'].maps.Map(
          this.streetviewMap.nativeElement
        );
        const panorama = new window['google'].maps.StreetViewPanorama(
          this.streetviewPano.nativeElement,
          {
            position: center,
            pov: { heading: this.heading, pitch: this.pitch },
            scrollwheel: this.scrollwheel,
          }
        );
        map.setStreetView(panorama);
        this.appRef.tick();
      });
    }
  }
  close() {
    this.showLeadData = 'property-view-close';
    this.showLeadDetails.next(false);
    this.ref.detectChanges();
  }

  saveLead(actionType: string) {
    let saveOpts = {
      leadId: this.leadDetails.id, notes: undefined,
      tags: undefined,
      isSaved: undefined
    };
    console.log("Clicked Save Lead")
    if (actionType === 'note') {
      this.savedLeadData.notes.push(this.note)
      saveOpts.notes = this.savedLeadData.notes.join("~~");
      this.note = '';
    } else if (actionType === 'tag') {
      this.savedLeadData.tags.push(this.tag)
      saveOpts.tags = this.savedLeadData.tags.join("~~");
      this.tag = '';
    }
    if (actionType === 'save') {
      this.savedLeadData.isSaved = !this.savedLeadData.isSaved;
      // @ts-ignore
      saveOpts.isSaved = this.savedLeadData.isSaved
      console.log(this.savedLeadData.isSaved);
    }
    this.leadsService.saveLeadData(saveOpts).subscribe((resp) => {
      if (resp.data) {
        this.toasterService.info("Lead data saved Successfully");
        this.editMode = ''
      } else {
        this.toasterService.error(resp.status.message)
      }
    });
  }

  // to remove saved tags and notes
  updateSavedData(actionType: string, index: number) {
    let updateOpts = {
      leadId: this.leadDetails.id, notes: undefined,
      tags: undefined,
      isSaved: undefined
    };
    console.log("Clicked update Lead")
    if (actionType === 'note') {
      this.savedLeadData.notes.splice(index, 1);
      updateOpts.notes = this.savedLeadData.notes.join("~~");
    } else if (actionType === 'tag') {
      this.savedLeadData.tags.splice(index, 1)
      updateOpts.tags = this.savedLeadData.tags.join("~~");
    }
    this.leadsService.saveLeadData(updateOpts).subscribe((resp) => {
      if (resp.data) {
        this.ref.detectChanges();
        this.toasterService.info(`${actionType} removed successfully`);
      } else {
        this.toasterService.error(resp.status.message)
      }
    });
  }

  downloadLead() {
    this.pdfDownloadInProgress = true;
    this.loaderService.isLoading.next(true);
    this.toasterService.info("Please wait, We are preparing your download");
    this.ref.detectChanges();
    let headerData = this.leadImage.nativeElement;
    let data = document.getElementById('leadDetails');
    let pdf = new jsPDF('l', 'px', 'a3'); //Generates PDF in landscape mode
    if (data && headerData) {
      html2canvas(headerData, {
        useCORS: true, width: headerData.scrollWidth, height: headerData.scrollHeight
      })
        .then(canvas => {
          const headerImage = canvas.toDataURL('image/png')
          pdf.addImage(headerImage, 'PNG', 4, 0, headerData.scrollWidth, headerData.scrollHeight);
          if (data) {
            html2canvas(data, {width: data.scrollWidth, height: data.scrollHeight})
              .then(canvas1 => {
                const contentImage = canvas1.toDataURL('image/png')
                pdf.addPage();
                // @ts-ignore
                pdf.addImage(contentImage, 'PNG', 4, 0, data.scrollWidth, data.scrollHeight);
                this.savedInfoButton.nativeElement.click();
                data = document.getElementById('leadDetails');
                if (data) {
                  html2canvas(data, {width: data.scrollWidth, height: data.scrollHeight})
                    .then(canvas1 => {
                      const contentImage = canvas1.toDataURL('image/png')
                      // switch back to original view
                      this.leadDetailsButton.nativeElement.click();
                      this.pdfDownloadInProgress = false;
                      this.ref.detectChanges();
                      pdf.addPage();
                      // @ts-ignore
                      pdf.addImage(contentImage, 'PNG', 4, 0, data.scrollWidth, data.scrollHeight);
                      this.loaderService.isLoading.next(false);
                      pdf.save('property.pdf');
                    })
                }
              })
          }
        }).catch((err) => {
        this.toasterService.info("Error in generating PDF, Please try again!")
      })
    }
  }

  formatPrice(price: any) {
    return this.helperService.formatPriceString(price);
  }

  enableEditMode(editMode: string) {
    console.log("Current edit mode", editMode)
    this.editMode = editMode;
    this.ref.detectChanges();
  }

  getLeadImages() {
    const {latitude, longitude} = this.leadDetails;
    this.images = [latitude, longitude];
  }

  selectLead(position: string) {
    this.leadsService.setSelectedLead(position);
  }

  uploadSurvey(leadId:any) {
     if(this.surveyForm.value.leadId===null){
        this.surveyForm.controls['leadId'].setValue(leadId);
     }
     if(this.surveyForm.value.closed===null){
        this.surveyForm.controls['closed'].setValue(false);
     }
      if(this.surveyForm.value.purchased===null){
        this.surveyForm.controls['purchased'].setValue(false);
     }
    const surveyData = this.surveyForm.value;
    if (!surveyData.purchased && !surveyData.closed) {
      this.toasterService.info("Please select at least one option");
      return 0;
    } else {
      this.leadsService.createLeadSurvey(surveyData).subscribe((response: any) => {
        if (response.data) {
          this.toasterService.info("Survey posted successfully");
          this.surveyForm.reset();
          this.closeSurveyModal.nativeElement.click();
        } else {
          const errorMessage = response.status.message;
          this.toasterService.info(errorMessage);
        }
      });
      return 1;
    }
  }

  getStreetView(){
    this.renderStreetView();
  }

  sendLeadsEmail() {
    this.userService.sendLeadsEmail([this.leadDetails]).subscribe((response: any) => {
      if (response.data) {
        this.toaster.info("Leads sent you your registered email address");
      } else {
        this.toaster.error("Error in sending email, Pleas try again!");
      }
    });
  }
}
