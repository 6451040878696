<div class="DashboardHeader2">
  <div class="category-part">
    <div *ngFor="let tag of tags">
      <button (click)="applyMotivationFilter(tag.name)" [ngClass]="tag.filtered ? 'tab-selected': ''"
              class="btn category-btn">
        <img src="../../../../assets/images/{{tag.icon}}">
        <p class="name">{{tag.label}}</p>
        <span class="number">{{tag.value}}</span>
      </button>
    </div>
  </div>
  <div class="filter-part">
    <h6>{{resultsCount}}</h6>
    <p>Results</p>
    <button (click)="showFiltersModal(true)" class="btn filter-btn">
      <!-- just add  (click)="navigateTo('filters')" to above button tag to show filte-->
      <img src="../../../../assets/images/dashboard/header/filters.png">
      <span>Filters</span>
    </button>
  </div>

</div>

<div id="wrapper">
  <!-- dashboard list view -->
  <app-dashboard-list *ngIf="dashboardView==='list' || dashboardView==='hybrid'"
                      [leadsData]="leads" [dashboardView]="dashboardView"
                      (changeDashBoardView)="changeDashboardView($event)"
                      (leadDetails)="updateLeadDetails($event)">
  </app-dashboard-list>
  <!--  Dashboard map view-->
  <app-dashboard-map
    *ngIf="dashboardView==='map' || dashboardView==='hybrid'"
    [searchData]="searchData" [leadsData]="leads" [dashboardView]="dashboardView"
    (leadDetailsId)="updateLeadDetails($event)">
  </app-dashboard-map>

  <!-- Dasboard Property view -->
  <lead-details-view *ngIf="showLeadDetails" [leadDetails]="leadDetails" [searchText]="searchData?.keyword || ''"
                     (showLeadDetails)="hideLeadDetailsPage($event)">
  </lead-details-view>

  <!-- dashboard filters -->
  <app-filters [searchData]="searchData" [resultsCount]="resultsCount"
               (applyUpdatedFilters)="applySelectedFilters($event)">
  </app-filters>

  <!--  switch dashboard view controls-->
  <div class="map-view-control" [ngClass]="dashboardView==='list'? 'd-none': ''"
       [class]="dashboardView==='hybrid'? 'half': 'full'">
    <div class="view-tabs" [ngClass]="dashboardView==='list'? 'active': ''"
         (click)="changeDashboardView('list')">
      <img src="../../../../assets/images/dashboard/pause-tab.png">
    </div>
    <div class="view-tabs hideTab" [ngClass]="dashboardView==='hybrid'? 'active': ''"
         (click)="changeDashboardView('hybrid')">
      <img src="../../../../assets/images/dashboard/column-tab.png">
    </div>
    <div class="view-tabs" [ngClass]="dashboardView==='map'? 'active': ''"
         (click)="changeDashboardView('map')">
      <img src="../../../../assets/images/dashboard/map-tab.png">
    </div>
  </div>
</div>