import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class FilteredLeadsService {
  leads = [];

  constructor() {
  }

  public filteredLeads: BehaviorSubject<any> = new BehaviorSubject([]);

  public updateFilteredLeads(leads: any) {
    this.leads = leads
    this.filteredLeads.next(leads);
  };
}
