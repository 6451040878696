import {Component, OnInit, ChangeDetectorRef, ViewChild, Inject} from '@angular/core';
import {LeadsService} from "../../../services/leads.service";
import {SearchService} from "../../../services/search.service";
import {FiltersComponent} from "../filters/filters.component";
import {Router} from "@angular/router";
import { DOCUMENT } from '@angular/common';

declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],

})
export class DashboardComponent implements OnInit {
  @ViewChild(FiltersComponent, {static: false}) filters: any;

  dashboardView: string;
  // showFilters = false;
  showLeadDetails = false;
  leadDetails: any;
  searchData: { keyword: string; type: string; } | undefined;
  leads: any;
  tags: any;
  resultsCount: any
  selectedFilters = [];

  constructor(private leadsService: LeadsService, private searchService: SearchService,
              private ref: ChangeDetectorRef, private router: Router,@Inject(DOCUMENT) private document: Document) {
    // @ts-ignore
    const data = this.router.getCurrentNavigation().extras
    // @ts-ignore
    if(data && data.keyword && data.keyword.length >0) {
      // @ts-ignore
      this.searchService.searchDetails = data;
      this.searchService.sendSearchData();
    }
    const dashview = localStorage.getItem("dashboardView");
    if(dashview && dashview.length > 0)
      this.dashboardView = dashview
    else
      this.dashboardView = "map";
  }

  ngOnInit(): void {
    this.searchService.getSearchData().subscribe(
      searchData => {
        this.searchData = searchData;
        this.searchLeads()
      }
    );
    this.document.body.classList.add('body-overflow');
  }

  searchLeads(filters = []) {
    this.showLeadDetails = false;
    if (!this.searchData?.type || !this.searchData.keyword)
      return;
    const options = {searchType: this.searchData.type, searchText: this.searchData.keyword};
    this.leadsService.searchLeads(options, filters)
      .subscribe((response: any) => {
        if (!response.data) {
          console.log("Error in fetching data", response.error);
        } else {
          this.leads = response.data.leads;
          this.tags = response.data.tags;
          this.resultsCount = response.data.totalCount;
        }
      })
  }

  changeDashboardView(view: string) {
    localStorage.setItem("dashboardView", view);
    this.dashboardView = view;
    console.log(this.dashboardView);
    this.ref.detectChanges();
    this.showLeadDetails = false;
  }

  updateLeadDetails(leadId: string) {
   //if we select marker and list
    localStorage.setItem("dashboardView", "hybrid");
    this.dashboardView = 'hybrid';
    console.log(this.dashboardView);
    this.ref.detectChanges();

    if (leadId.length) {
      this.leadsService.fetchLeadById(leadId)
        .subscribe((data: any) => {
          this.leadDetails = data.data;
          this.showLeadDetails = true;
          this.ref.detectChanges();
        })
    }
  }

  hideLeadDetailsPage(flag: boolean) {
    this.showLeadDetails = flag;
  }

  // openFilters() {
  //   $('#filtersModal').modal('show');
  //   this.filters.setSearchDataAndResultsCount(this.searchData, this.resultsCount);
  // }

  applySelectedFilters(data: any) {
    console.log("Updating filters:", data)
    this.selectedFilters = data;
    this.updateTagsFilters(data[0].data)
    this.searchLeads(this.selectedFilters);
    // this.showFiltersModal(false);
    // should update the search result
  }

  applyMotivationFilter(filterName: string) {
    this.filters.updateFlagFilter(filterName, "motivations", true);
  }

  updateTagsFilters(data: any) {
    data.forEach((item: { name: string; value: any; }) => {
      const updatableTag = this.tags.find((data: { name: string; }) => data.name === item.name)
      const index = this.tags.indexOf(updatableTag);
      this.tags[index].filtered = item.value;
    });
  }

  showFiltersModal(flag: boolean) {
    console.log("Filters modal")
    this.filters.showFiltersTab(flag);
    this.ref.detectChanges();
  }
}
