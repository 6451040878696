<div class="w-100">
    <button
      nbButton
      size="tiny"
      type="button"
      status="primary"
      (click)="edit()"
      class="badge badgeHistory"
    >
      Edit
    </button>
  
    <button
      nbButton
      size="tiny"
      type="button"
      status="danger"
      class="badge badgeHistory"
      (click)="searchHistory()"
    >
      History
    </button>
  </div>
