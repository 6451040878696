<!--<ag-grid-angular-->
<!--  style="width: 100%; height: 600px;"-->
<!--  class="ag-theme-material"-->
<!--  [rowData]="surveyData"-->
<!--  [defaultColDef]="defaultColumnDefs"-->
<!--  [columnDefs]="columnDefs"-->
<!--  [rowStyle]="rowStyles"-->
<!--  rowSelection="single"-->
<!--  [suppressCellSelection]=true-->
<!--  [rowMultiSelectWithClick]="true"-->
<!--  (gridReady)="onGridReady($event)"-->
<!--  (columnValueChanged)="changedData($event)"-->
<!--&gt;-->
<!--</ag-grid-angular>-->

<div class="survey-action">
  <div class="container">
    <div class="backHome">
      <a href="/dashboard" class="btn back">
        <img src="assets/images/home.png">Back To Home
      </a>
    </div>
    <div class="table">
      <h4>Survey Action</h4>
      <div class="table-responsive">
        <table>
          <thead>
          <tr>
            <!-- <th scope="col">ID</th> -->
            <th scope="col">User Email</th>
            <th scope="col">Lead Address</th>
            <th scope="col">Purchased</th>
            <th scope="col">Closed</th>
            <th scope="col">Action</th>
            <th scope="col"></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let survey of surveyData;let i = index;">
            <!-- <td scope="row">{{survey.id}}</td> -->
            <td scope="row">{{survey.email}}</td>
            <td>{{survey.address}}</td>
            <td>{{survey.closed}}</td>
            <td>{{survey.purchased}}</td>
            <td>
              <select [(ngModel)]=survey.isAccepted>
                 <option [ngValue]="undefined" selected>Search</option>
                <option [value]=false>Decline</option>
                <option [value]=true>Accept</option>
                <option [value]=null>No Action</option>
              </select>
            </td>
            <td>
              <button (click)="updateLeadSurvey()" class="btn updateBtn">Update</button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>


