<div class="globalSetting">
  <div class="container">
    <div class="backHome">
      <a href="/dashboard" class="btn back">
        <img src="assets/images/home.png">Back To Home
      </a>
    </div>
    <div class="globalContent">
      <div class="topHead">
        <h4>Global Settings</h4>
        <button (click)="toggleEditMode()" data-toggle="modal" data-target="#addSettings" class="btn addSetting">
          <i class="fa fa-plus mr-2"></i>Add Setting
        </button>
      </div>
      <ag-grid-angular
        style="width: 100%; height: 600px;"
        class="ag-theme-material"
        [rowData]="settings"
        [rowStyle]="rowStyles"
        [defaultColDef]="defaultColumnDefs"
        [columnDefs]="columnDefs"
        (gridReady)="onGridReady($event)">
      </ag-grid-angular>
    </div>
  </div>
</div>

<!-- User edit modal starts -->
<div class="modal fade" id="addSettings" tabindex="-1" role="dialog" aria-labelledby="User Edit modal"
     aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <app-loader></app-loader>
      <div class="modal-header text-center">
        <h4 class="modal-title w-100 font-weight-bold">Add Setting</h4>
        <button #closeAddSettingsModal type="button" class="close" data-dismiss="modal" aria-label="Close">
          <!-- <span aria-hidden="true">&times;</span> -->
        </button>
      </div>

      <div class="modal-body">
        
        <div class="md-form">
          <label for="name">Name</label>
          <select [(ngModel)]="settingsForm.name" id="name" class="form-control" placeholder="Choose an option" aria-placeholder="Choose an option">
            <!--<option>Threshold</option>-->
            <option>Price Monthly Subs</option>
            <option>Price Yearly Subs</option>
            
          </select>
        </div>
        <div class="md-form">
          <label for="value">Value</label>
          <input [(ngModel)]="settingsForm.settings.value" id="value" class="form-control" placeholder="Enter your value"/>
        </div>
        <div class="md-form">
          <label for="date">Effective Date</label>
          <input id="date" [(ngModel)]="settingsForm.effectiveDate" class="form-control" type="date">
          <p class="infoText"><strong>*</strong> Leave the above field blank if you want to apply this setting
            immediately</p>
        </div>
      </div>
      <div class="modal-footer mx-auto">
        <button (click)="addNewSetting()" class="btn Added">Add Setting</button>
      </div>
    </div>
  </div>
</div>

<!-- User edit modal ends-->
