<div class="subscription">
  <div class="backHome">
    <a href="/dashboard" class="btn back">
      <img src="assets/images/home.png">Back To Home
    </a>
  </div>
  <div class="container">
    <div class="pricingCard">
      <h2>My Subscription</h2>
      <div class="row">
        <div class="col-lg-6 col-sm-6 col-12">
          <!-- a class="active defines the selected card" -->
          <div (click)="changePlanType('monthly')" [ngClass]="selectedPlan === 'monthly'? 'active':''" class="details">
            <div class="selected">
              <img src="assets/images/green-check-full.png" alt="selected">
            </div>
            <h6>Monthly</h6>
            <h2>19.99 <span>$</span></h2>
            <div class="item-list">
              <ul>
                <li><img src="assets/images/Check Icon.png">Pre-foreclosures</li>
                <li><img src="assets/images/Check Icon.png">Inheritance Deeds</li>
                <li><img src="assets/images/Check Icon.png">Vacant Properties</li>
                <li><img src="assets/images/Check Icon.png">Quit Claims</li>
                <li><img src="assets/images/Check Icon.png">Absentee Owners</li>
                <li><img src="assets/images/Check Icon.png">High Equity</li>
                <li><img src="assets/images/Check Icon.png">Reverse Mortgages</li>
                <li class="not-available">Record Validation</li>
                <li class="not-available">List Suppression</li>
                <li class="not-available">Duplicate Removal</li>
              </ul>
            </div>
            <div class="text-center">
              <button [disabled]="buttonTexts.monthly === 'Active' || selectedPlan!=='monthly'"
                    (click)="updateSubscription()" class="btn trial-btn">{{buttonTexts.monthly}}
              </button>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-sm-6 col-12">
          <div (click)="changePlanType('yearly')" [ngClass]="selectedPlan === 'yearly'? 'active':''" class="details">
            <div class="selected">
              <img src="assets/images/green-check-full.png" alt="selected">
            </div>
            <h6>Yearly</h6>
            <h2>49.99 <span>$</span></h2>
            <div class="item-list">
              <ul>
                <li><img src="assets/images/Check Icon.png">Pre-foreclosures</li>
                <li><img src="assets/images/Check Icon.png">Inheritance Deeds</li>
                <li><img src="assets/images/Check Icon.png">Vacant Properties</li>
                <li><img src="assets/images/Check Icon.png">Quit Claims</li>
                <li><img src="assets/images/Check Icon.png">Absentee Owners</li>
                <li><img src="assets/images/Check Icon.png">High Equity</li>
                <li><img src="assets/images/Check Icon.png">Reverse Mortgages</li>
                <li><img src="assets/images/Check Icon.png">Record Validation</li>
                <li><img src="assets/images/Check Icon.png">List Suppression</li>
                <li><img src="assets/images/Check Icon.png">Duplicate Removal</li>
              </ul>
            </div>
            <div class="text-center">
              <button [disabled]="buttonTexts.yearly === 'Active' || selectedPlan!=='yearly'"
                    (click)="updateSubscription()" class="btn trial-btn">{{buttonTexts.yearly}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
