import {Component, Inject, OnInit} from '@angular/core';
import {UserService} from "../../../services/user.service"
import { ToastrService } from  "ngx-toastr";
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {
  selectedPlan = '';
  buttonTexts = {
    monthly: "Activate",
    yearly: "Activate"
  }
  active: string;
  subscription: any;

  constructor(private userService: UserService, private toaster: ToastrService,@Inject(DOCUMENT) private document: Document) {
  }

  ngOnInit(): void {
    this.document.body.classList.remove('body-overflow');
    this.getActiveSubscriptions();
  }

  changePlanType(plan: string) {
    this.selectedPlan = plan;
  }

  getActiveSubscriptions() {
    this.userService.getUserSubscription().subscribe((response) => {
      const data = response.data;
      if (data) {
        this.subscription = data;
        this.selectedPlan = this.subscription.subscriptionType;
        this.formatSubsButtonText();
        localStorage.setItem('subscription', JSON.stringify(data));
      }
      else {
        this.selectedPlan = 'monthly'
      }
    });
  }

  formatSubsButtonText() {
    if(this.subscription && this.subscription.subscriptionType === 'monthly') {
      this.buttonTexts = {
        monthly: "Active",
        yearly: "Upgrade"
      }
    }
    else if(this.subscription && this.subscription.subscriptionType === 'yearly') {
      this.buttonTexts = {
        monthly: "Downgrade",
        yearly: "Active"
      }
    }
  }

  updateSubscription() {
    if(this.subscription && this.subscription.subscriptionType !== this.selectedPlan) {
      this.userService.updateSubscription({planType: this.selectedPlan}).subscribe((response)=>{
        if(response.data) {
          this.getActiveSubscriptions();
        }
        else {
          this.toaster.info("Error in updating subscription, please try again!")
        }
      });
    }
    else {
      this.userService.createSubscription({ planType: this.selectedPlan}).subscribe((response)=>{
        if(response.data) {
          this.getActiveSubscriptions();
        }
        else {
          this.toaster.info("Please add a payment method before activating subscription")
        }
      });
    }
  }
}
