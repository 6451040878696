import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private baseUrl = environment.serviceUrls.leads;
  public searchDetails = {
    keyword: "Cranbury",
    type: "city"
  };
  searchHeaderData=false;
  private searchData: BehaviorSubject<{keyword: string, type: string}> = new BehaviorSubject(this.searchDetails);

  constructor(public http: HttpClient) {
    // to initiate data in consumer components
  
    // if user has city then search by city else search by canbarry
  if (localStorage.getItem("search") === null) {
   let user =JSON.parse(localStorage.getItem("user") || '');
    if(user.city!=='' && user.city!==null){
      localStorage.setItem('search',JSON.stringify({searchText:user.city,searchType:'city'}))
        this.searchDetails.keyword=user.city;
      this.searchDetails.type='city'
    }
  }else{
    let searchStr=localStorage.getItem("search") 
    if(searchStr){
     let search=JSON.parse(searchStr)
      if(search.searchText!=='' && search.searchText!==null){
      this.searchDetails.keyword=search.searchText;
      this.searchDetails.type=search.searchType
    }else{
       this.searchDetails.keyword="Cranbury";
      this.searchDetails.type='city'
      localStorage.setItem('search',JSON.stringify(this.searchDetails))
    }
    }
  }

     
    //search by user city
   
    
     this.searchHeaderData=false;
    this.sendSearchData();
  }

  public sendSearchData() {
    this.searchData.next(this.searchDetails);
    this.searchHeaderData=true;
  };

  getSearchData(): Observable<any> {
    return this.searchData.asObservable();
  }

  public getSearchKeywords(): Observable<any> {
    console.log("Search started");
    return this.http.get(`${this.baseUrl}/search/keywords`)
  }

   public postSearchHistory(option:any) {
    console.log("Search history saved");
    return this.http.post(`${this.baseUrl}/leads/save/history`,option)
  }

    public userSearchHistory(userId:any) {
    console.log("User history get");
    return this.http.get(`${this.baseUrl}/user/search/history?userId=${userId}`,)
  }
}
