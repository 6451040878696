<div class="preloader" [hidden]='!loading'>
  <div class="status">
    <div class="bouncing-loader">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
  <!-- <div class="loader"></div> -->
</div>
