import {NgModule} from '@angular/core';
// angular data tables for the list view of leads
import {CommonModule} from "@angular/common";
import {SearchUserComponent} from './search-user/search-user.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AgGridModule} from "ag-grid-angular";
import { GlobalSettingsComponent } from './global-settings/global-settings.component';
import { SurveyActionComponent } from './survey-action/survey-action.component';
import { LoaderComponent } from '../loader/loader.component';
import { Component } from 'ag-grid-community';
import { ComponentsModule } from '../components.module';

@NgModule({
  exports: [
  ],
  declarations: [
    SearchUserComponent,
    GlobalSettingsComponent,
    SurveyActionComponent,
  ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        AgGridModule,
        ComponentsModule
    ],
  providers: [],
})
export class AdminModule {
}
