import {Component, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeComponent} from './components/home/home.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {TokenInterceptor} from './auth/token.interceptor';
import {ErrorInterceptor} from './helper/error.interceptor';
import {HttpClientModule} from '@angular/common/http';
import {HeaderComponent} from './components/header/header.component';
import {LeadsService} from './services/leads.service';
import {UserService} from "./services/user.service";

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastrModule} from 'ngx-toastr';
import {DashboardModule} from './components/dashboards/dashboard.module';
import {ProfileDrawerModule} from './components/profileDrawer/profileDrawer.module';
import {CarouselModule} from 'ngx-owl-carousel-o';
import {AdminModule} from './components/admin/admin.module';
import {ConnectionServiceModule} from 'ng-connection-service';
import {LoaderComponent} from './components/loader/loader.component';
import {LoaderInterceptor} from "./helper/loader.interceptor";
import { CellButtonsComponent } from './shared/components/cell-buttons/cell-buttons.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ComponentsModule } from './components/components.module';
  import {AgmCoreModule} from '@agm/core';

@NgModule({
  exports: [
    CarouselModule
  ],
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    CellButtonsComponent,
    ResetPasswordComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(),
    FormsModule,
    DashboardModule,
    ProfileDrawerModule,
    CarouselModule,
    AdminModule,
    ConnectionServiceModule,
    ComponentsModule,
   AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDWiXxOG8yypGp4Dx-djHKEW16fGuP53PQ'
    })
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true},
    LeadsService,
    UserService,
  ],
  entryComponents:[CellButtonsComponent],
  bootstrap: [AppComponent]
})
export class AppModule {
}
