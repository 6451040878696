import {Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef} from '@angular/core';
import {LeadsService} from "../../../services/leads.service";
import {ToastrService} from "ngx-toastr";
import { filter } from 'rxjs/operators';
declare var $:any;

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {
  activeTab = 'current';
  showFilters = false;
  searchData: any;
  resultsCount: number
  savedFilters: any;
  filterName: string = '';
  maxRange:any
  filtersData: [{ name: string; type: string, rank: number, data: any, label: string, values: any, value: any }];
  @Output() applyUpdatedFilters = new EventEmitter<any>();
  minVal: undefined;

  @Input("searchData") set setSearchData(searchData: any) {
    this.searchData = searchData;
  }
  @Input("resultsCount") set setResultsCount(resultsCount: number) {
    this.resultsCount = resultsCount;
  }

  constructor(private leadsService: LeadsService, private toaster: ToastrService, private ref: ChangeDetectorRef) {
  }

  rangeLimit(selectedValue:any, minRange:any){
    let startIndex;
    let rangStart = selectedValue;
    let rangeArray=minRange;
    for(let i=0; i <=minRange.length; i++){
      if(minRange[i] == rangStart){
        startIndex = i + 1;
        break;
      }
    }
    this.maxRange = rangeArray.slice(startIndex,minRange.length);
  
  }

  ngOnInit(): void {
    this.getFiltersData();
    this.getSavedFilters();
  }
  public setSearchDataAndResultsCount(searchData:any, resultsCount:number) {
  this.searchData = searchData;
  this.resultsCount = resultsCount;
  }

  selectFilter(name: string, value: any, type: string, valueType: any) {
    console.log(name, value,type,valueType)
    if (type === 'flag') {
      console.log({flag: "flag"})
     this.updateFlagFilter(name, valueType);
    }
    this.updateResultsCount();
  }

  private updateFlagFilter(name: string, valueType: any, apply = false){
    console.log("filter is getting updated", name,valueType);
    const updatedFilterItem = this.filtersData.find((item) => item.name === valueType);
    console.log(updatedFilterItem, "updatable found");
    if (updatedFilterItem) {
      const index = this.filtersData.indexOf(updatedFilterItem)
      const updatedDataItem = updatedFilterItem.data.find((item: { name: string; })=> item.name === name)
      const updatedDataItemIndex = this.filtersData[index].data.indexOf(updatedDataItem);
      const currentValue = this.filtersData[index].data[updatedDataItemIndex].value;
      if(currentValue) {
        console.log("removing filter")
        this.filtersData[index].data[updatedDataItemIndex].value = null;
      }
      else {
        this.filtersData[index].data[updatedDataItemIndex].value = true;
      }
      console.log("UpdatedValue: ", this.filtersData[index].data[updatedDataItemIndex].value)
    }
    if(apply)
      this.applyFilters();
  }

  public applyFilters() {
    this.applyUpdatedFilters.next(this.filtersData);
    this.showFiltersTab(false);
    // $('#filtersModal').modal('hide');
  }

  public showFiltersTab(flag:boolean) {
    this.showFilters = flag;
  }

  public saveSelectedFilters() {
    
    
    let filters:any=this.filtersData;
    let minVal=filters[4]?.values?.min;
    let maxVal=filters[4]?.values?.max;
    let regex=/^[0-9]{4}$/
    if(regex.test(minVal)&&regex.test(maxVal)){
       if(!this.filterName) {
        this.toaster.info("Please enter a label before saving filters")
        return false;
      }
      let options = {
        name: this.filterName,
        filters: this.filtersData
      }
      console.log(this.filterName);
      this.leadsService.saveFilters(options).subscribe((response)=>{
        if(response.data) {
          // @ts-ignore
          options.date = "Today"
          this.savedFilters.push(options);
          this.toaster.info("Filters saved successfully!");
        }
      })
    }

   else{
       this.toaster.info('Please enter correct year')
     }
     return true;
   // selected filters should be saved
  }

  private updateResultsCount() {
    const searchOpts = {searchType: this.searchData.type, searchText: this.searchData.keyword};
    this.leadsService.getResultsCount(searchOpts, this.filtersData).subscribe((result)=>{
      this.resultsCount = result.data.totalCount;
    })
  }

  private getSavedFilters() {
    this.leadsService.getSavedFilters().subscribe((result)=>{
      this.savedFilters = result.data;
      this.ref.detectChanges();
    })
  }
  deleteSaveFilters(Option:any) {
    console.log(this.savedFilters)
      this.leadsService.deleteSavedFillterLeads(Option).subscribe((response) => {
      this.savedFilters.pop(Option);
    })
  }

  public resetFilters() {
    this.getFiltersData()
  }

  private getFiltersData(){
    this.leadsService.getFiltersData().subscribe((resp: any) => {
      this.filtersData = resp.data;
      this.ref.detectChanges();
    });
  }
  // this is helper to choose to show the motivation filters in saved filter in list or in a dropdown
  // if current index is less then 3 then show in list otherwise in dropdown
  // we need this function because from the index we can't predict if that filter is appied or not
  // as applied filer is based on the value inside the filter object, if true applied otherwise not applied
  motivSavedFilterHelperIndex = 0;
  public motivSavedFilterHelper(data:any,length: number, currentIndex: number){
    let showBehaviour = ''
    if(data.value)
      this.motivSavedFilterHelperIndex++;
    if(data.value && this.motivSavedFilterHelperIndex <=3)
      showBehaviour = 'list';
    else if(data.value && this.motivSavedFilterHelperIndex >3)
      showBehaviour = 'dropdown';
    // if index is last then reset the motivSavedFilterHelperIndex to avoid problem in next render
    if(currentIndex === length -1)
      this.motivSavedFilterHelperIndex = 0;
    return showBehaviour;
  }

  applySavedFilter(index: number){
    this.filtersData = this.savedFilters[index].filters;
    this.applyFilters();
    this.toggleActiveTab('current');
  }

  toggleActiveTab(tab: string) {
    this.activeTab = tab;
  }

  minYear($event:any){
    
     var itIsNumber = /^[0-9]{4}$/.test($event.target.value);
     if(itIsNumber){
    let min:any=this.filtersData;
    let minRange=min[4].data;
    let minName=min[4].name; 
    this.rangeLimit($event.target.value, minRange);
    this.selectFilter(minName,' ' , 'range', 'min' )
     }else{
       this.toaster.info('Please enter correct year')
     }
    
  }

  maxYear($event:any){  
    var itIsNumber = /^[0-9]{4}$/.test($event.target.value);
    if(itIsNumber){
    let max:any=this.filtersData;
    let maxRange=max[4].data;
    let maxName=max[4].name; 
    this.rangeLimit($event.target.value, maxRange);
    this.selectFilter(maxName,' ' , 'range', 'max' )
     }else{
       this.toaster.info('Please enter correct year')
     }
    console.log("min"+$event.target.value)
  }
}
