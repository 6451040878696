// loader-interceptor.service.ts
import { Injectable } from '@angular/core';
import {
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderService } from '../services/loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];

  constructor(private loaderService: LoaderService) { }

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.loaderService.isLoading.next(this.requests.length > 0);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log("entereceptor",req)
    let interceptorExcludeList = [{"url":'https://realestate-backend.dev.ziggletech.com/users/api/v1/user',"method":'PUT'},
    // {"url":'https://realestate-backend.dev.ziggletech.com/users/api/v1/admin/settings',"method":'POST'},
    {"url":'https://realestate-backend.dev.ziggletech.com/leads/api/v1/leads/search/count/city/',"method":'POST'},
    {"url":'https://realestate-backend.dev.ziggletech.com/users/api/v1/admin/user',"method":'PUT'},
    {"url":'https://realestate-backend.dev.ziggletech.com/payments/api/v1/payments/delete/payment-profile',"method":'DELETE'}
    
  ];
    let load=true;
    for(let i=0;i<interceptorExcludeList.length;i++){
      if(req.url===interceptorExcludeList[i].url && req.method===interceptorExcludeList[i].method){
        load=false;
        break;
      }else if((req.url.includes(interceptorExcludeList[i].url) && req.method===interceptorExcludeList[i].method) ){
        load=false;
        break;
      }
    }
    if(load){

    this.requests.push(req);

    console.log("No of requests--->" + this.requests.length);

    this.loaderService.isLoading.next(true);
    return Observable.create((observer: { next: (arg0: HttpResponse<any>) => void; error: (arg0: any) => void; complete: () => void; }) => {
      const subscription = next.handle(req)
        .subscribe(
          event => {
            if (event instanceof HttpResponse) {
              this.removeRequest(req);
              observer.next(event);
            }
          },
          err => {
            alert('error' + err);
            this.removeRequest(req);
            observer.error(err);
          },
          () => {
            this.removeRequest(req);
            observer.complete();
          });
      // remove request from queue when cancelled
      return () => {
        this.removeRequest(req);
        subscription.unsubscribe();
      };
    });
  }else{
    return Observable.create((observer: { next: (arg0: HttpResponse<any>) => void; error: (arg0: any) => void; complete: () => void; }) => {
      const subscription = next.handle(req)
        .subscribe(
          event => {
            if (event instanceof HttpResponse) {
              this.removeRequest(req);
              observer.next(event);
            }
          },
          err => {
            alert('error' + err);
            this.removeRequest(req);
            observer.error(err);
          },
          () => {
            this.removeRequest(req);
            observer.complete();
          });
      // remove request from queue when cancelled
      return () => {
        this.removeRequest(req);
        subscription.unsubscribe();
      };
    });
  }
  }
}
