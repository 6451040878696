import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {LeadsService} from "../../../services/leads.service";
import {ToastrService} from "ngx-toastr";
import 'jspdf-autotable';
import {DownloadService} from "../../../services/download.service";
import {HelperService} from "../../../services/helper.service";
import {FilteredLeadsService} from "../../../services/filtered-leads.service";
import {Select2Data} from 'ng-select2-component';
import {LoaderService} from "../../../services/loader.service";
import {UserService} from "../../../services/user.service";
import { DOCUMENT } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';


@Component({
  selector: 'app-dashboard-list',
  templateUrl: './dashboard-list.component.html',
  styleUrls: ['./dashboard-list.component.scss']
})
export class DashboardListComponent implements OnInit {

  leads = [];
  dashboardView = 'list';
  split: boolean;
  ArrayOfLeads=[];

   sortingLeads!: FormGroup;
   

  // for select2 dropdown
  selectedValue: any;
  leadColOptions: any= this.helperService.getLeadColOptions();



  // drop(event: CdkDragDrop<any[]>) {
  //   moveItemInArray(this.movies, event.previousIndex, event.currentIndex);
  // }
  

  private gridApi: any;
  private gridColumnApi: any;
  leadSorting: any;

  @Input('leadsData') set setLeadsData(leadsData: any) {
    if(leadsData?.length >0){
      this.leads = leadsData;
      this.rowData = leadsData;
      console.log('No. of leads '+leadsData?.length)
    }else{
      this.leads = [];
      this.rowData = [];
      this.noData = 'lead not available'
      console.log('No. of leads '+leadsData?.length +' ---- ' +this.noData)
    }
    
  }

  @Input('dashboardView') set setDashboardView(dashboardView: string) {
    this.dashboardView = dashboardView;
    [this.columnDefs, this.selectedValue] = this.helperService.getDefaultLeadCols(this.dashboardView);
    this.split = dashboardView === 'hybrid';
  }

  @Output() changeDashBoardView = new EventEmitter<string>(true);
  @Output() leadDetails = new EventEmitter<string>();

  defaultColumnDefs = {
    filter: true,
    sortable: true,
    filterParams: {
      buttons: ["apply", "clear", "cancel"],
      closeOnApply: true,
    },
    suppressCellSelection: true,
    resizable: true,
    draggable: true
  }
  rowStyles = this.helperService.getRowStyles();
  columnDefs: any;
  rowData: any;
  noData: any;
  selectedLeads = [];
  LeadsInCity:any;

  constructor(private leadsService: LeadsService, private toaster: ToastrService,
              private downloadService: DownloadService, private helperService: HelperService,
              private filteredLeadsService: FilteredLeadsService, private loaderService: LoaderService,
              private userService: UserService,@Inject(DOCUMENT) private document: Document,
              private formbuilder: FormBuilder) {
              [this.columnDefs, this.selectedValue] = this.helperService.getDefaultLeadCols(this.dashboardView || 'hybrid');
  }

  ngOnInit(): void {
    this.watchFilteredLeads();
    this.document.body.classList.add('body-overflow');
    this.document.getElementsByClassName('leaflet-bar');
    this.InitLeads();
    //set leads in city
      if (localStorage.getItem("search")  ) {
   let city =JSON.parse(localStorage.getItem("search") || '');
   console.log(city)
    if(city.searchText!=='' && city.searchText!==null){
        this.LeadsInCity=city.searchText
    }
  }

  }

  // emit dashboard view changes to parent
  changeDashboardView(view: string) {
    this.changeDashBoardView.emit(view);
  }

  onGridReady(params: { api: any; columnApi: any; }) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  LoadLeadDetails(leadData: any) {
    console.log("Lead Clicked:", leadData);
    this.leadDetails.next(leadData.data.id);
    //set lead info
    this.leadsService.leadListClick=leadData.data.id;
    this.leadsService.leadLatList=leadData.data.latitude;
     this.leadsService.leadLongList=leadData.data.longitude
  }
  
  onSelectionChanged() {
    console.log("SelectionUpdate");
    let selectedRows = this.gridApi.getSelectedRows();
    this.selectedLeads = selectedRows;
  }

  saveLeads() {
    if (this.selectedLeads?.length === 0) {
      this.toaster.info("Please select at least one lead");
      return 0;
    }
    // @ts-ignore
    const selectedLeadIds = this.selectedLeads.map((lead) => lead.id);
    console.log("Leads to be saved", selectedLeadIds)
    this.leadsService.saveLeadData({
      isSaved: true,
      leadIds: selectedLeadIds
    }).subscribe((response) => {
      if (response.data) {
        this.gridApi.deselectAll();
        this.toaster.info("Your leads saved successfully");
      } else
        this.toaster.error("Error in saving leads, Please try again!")
    });
    return 1;
  }

  downloadPdf() {

    // check treshold is greater than zero

     let user =JSON.parse(localStorage.getItem("user") || '');
    if (this.selectedLeads?.length === 0) {
      this.toaster.info("Please select at least one lead");
      return 0;
    }
    if(user.threshold>this.selectedLeads?.length){
       this.toaster.info("Please wait while we are processing your download");
        this.loaderService.isLoading.next(true);
        let data = this.selectedLeads;
        data = data.map((item) => {
          // @ts-ignore
          delete item.shortAddress;
          // @ts-ignore
          delete item.motivations;
          return item;
        })
        this.downloadService.downloadFile(data);
        this.loaderService.isLoading.next(false);
        this.userService.updateThreshold(this.selectedLeads?.length).subscribe((response: any) => {
        this.userService.setUser(response.data)
    });
        // const doc = new jsPDF('landscape', 'pt', 'A1');
        // const colsValues = Object.keys(data[0]);
        // const cols = colsValues.map((col) => {
        //   return {
        //     header: col,
        //     dataKey: col
        //   }
        // })
        // // @ts-ignore
        // doc.autoTable({
        //   columns: cols, body: data, tableWidth: 2000,
        // });
        // doc.save('leads.pdf');
        return 1;
    }else{
      this.toaster.info("Please Increse Threshold to download leads");
      return 0;
    }
    
  }

  watchFilteredLeads() {
    this.filteredLeadsService.filteredLeads.subscribe((leads: any) => {
      console.log("Updating filtered leads", leads?.length);
      if (leads?.length > 0) {
        this.leads = leads;
        this.rowData = leads;
      }
    })
  }

  // function used in select2 for any further process
  changeLeadColumn() {
   
    this.columnDefs = this.helperService.updateLeadCols(this.selectedValue);
  }

  sendLeadsEmail() {
    console.log("Sending Leads", this.selectedLeads?.length);
    if (this.selectedLeads?.length > 50) {
      this.toaster.info("You can not email more than 50 leads at a time!");
    } else {
      this.userService.sendLeadsEmail(this.selectedLeads).subscribe((response: any) => {
        if (response.data) {
          this.toaster.info("Leads sent you your registered email address");
        } else {
          this.toaster.error("Error in sending email, Pleas try again!");
        }
      });
    }
  }
  InitLeads(){
  //convert object to array

    this.ArrayOfLeads = this.leadColOptions.map(function (obj:any) {
    return obj.value;
    });

    //check for selected feilds in fillter
    let checkedFillter:any=[];
      for(let i=0;i<(this.ArrayOfLeads)?.length;i++){
        for(let j=0;j<(this.selectedValue)?.length;j++){
          if(this.ArrayOfLeads[i]===this.selectedValue[i]){
            checkedFillter[i]=true;
            break;
          }
        }
        if(!checkedFillter[i]){
            checkedFillter[i]=false;
          }
      }
    

    this.sortingLeads = this.formbuilder.group({
      leadSort: this.formbuilder.array(checkedFillter)
    });  

  }
  sortLeads(){
    let values= this.sortingLeads.value.leadSort;
    this.leadSorting=[];
    for(let i=0;i<values?.length;i++){
      if(values[i]===true){
        this.leadSorting.push(this.ArrayOfLeads[i])
      }
    }
   
     this.selectedValue=this.leadSorting;
    this.changeLeadColumn();
     
  }
}
