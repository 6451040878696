import {Component, Inject, OnInit} from '@angular/core';
import {LeadsService} from "../../../services/leads.service";
import {SearchService} from "../../../services/search.service";
import {Router} from "@angular/router";
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-search-history',
  templateUrl: './search-history.component.html',
  styleUrls: ['./search-history.component.scss']
})
export class SearchHistoryComponent implements OnInit {
  searchHistoryData: any;

  constructor(private leadsService: LeadsService, private searchService: SearchService, private router: Router,@Inject(DOCUMENT) private document: Document) {
  }

  ngOnInit(): void {
    this.document.body.classList.remove('body-overflow');
    this.leadsService.getSearchHistory().subscribe((response) => {
      if (response.data)
        this.searchHistoryData = response.data;
    })
  }

  searchById(data: any) {
    console.log("searched", data);
    const routeData = { keyword: data.searchText, type: data.type };
    // @ts-ignore
    this.router.navigate(['dashboard'], routeData)
  }
}
