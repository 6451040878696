import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {UserService} from "../../../services/user.service";
import {StripeService, StripeCardComponent} from 'ngx-stripe';
import {
  StripeCardElementOptions,
  StripeElementsOptions,
} from '@stripe/stripe-js';
import {FormBuilder,FormControl, FormGroup, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import { DOCUMENT } from '@angular/common';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
    profile!: FormGroup;
  @ViewChild(StripeCardComponent) card: StripeCardComponent | undefined;
  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#485160',
        fontWeight: '500',
        fontFamily: 'DM Sans',
        fontSize: '14px',
        lineHeight: '18px',
        '::placeholder': {
          color: '#CFD7E0',
        },
      },
    },
    hidePostalCode: true,
  };
  elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };

  profileDetails: { email: string, name: string, phone: string,county:string,city:string,zipCode: string, role: string };
  paymentMethods: [{ card: any, isDefault: boolean, id: number }]
  numberOfPaymentProfiles = 0
  editMode = '';
  cancelCard = '';
  passwordChangeForm = new FormGroup({
    oldPassword: new FormControl('', Validators.required),
    newPassword: new FormControl('', Validators.minLength(8)),
    confirmNewPassword: new FormControl('', Validators.minLength(8))
  });
  @ViewChild('closePasswordModal') closePasswordModal: ElementRef

  constructor(private userService: UserService, private stripeService: StripeService, private toaster: ToastrService,private formbuilder: FormBuilder,
    @Inject(DOCUMENT) private document: Document,) {
    this.profileDetails = {email: '', name: '', phone: '',county:'',city:'',zipCode:'', role: ''};
  }

  ngOnInit(): void {
    this.document.body.classList.remove('body-overflow');
    this.getProfileDetails();
    this.getPaymentMethods();
        this.profile = this.formbuilder.group({
        name:["", [Validators.required]],
        phone:["", [Validators.required]],
        city:[""],
        zipcode:[""]
      });
  }

  getProfileDetails() {
    this.userService.getProfileDetails().subscribe((response) => {
      if (response.data)
        this.profileDetails = response.data;
    })
  }

  getPaymentMethods() {
    this.userService.getPaymentMethods().subscribe((response) => {
      console.log(response.data);
      if (response.data) {
        this.paymentMethods = response.data;
        this.numberOfPaymentProfiles = this.paymentMethods.length;
      }
    })
  }

  deletePaymentMethods(Option:any,isDefault:any) {
   
      if(isDefault){
         this.toaster.info("You cannot delete default payment details")
      }else{
        this.userService.deletePaymentMethods(Option).subscribe((response) => {
        console.log(response.data);
         location.reload();
       })
      }

  }
  addPaymentMethod(): void {
    this.stripeService
      // @ts-ignore
      .createToken(this.card.element, {
        name: this.profileDetails.name,
      })
      .subscribe((result) => {
        console.log(result, result.token);
        if (result.token) {
          // Use the token
          this.userService.addPaymentMethod(result.token)
            .subscribe((data: { error: any; }) => {
              if (data.error) {
                //handle the error
                console.log(data.error);
              }
              // created payment profile
              this.getPaymentMethods();
              this.editMode = '';
            })
            window.scroll(0, 0)
        } else if (result.error) {
          // Error creating the token
          console.log(result.error.message);
        }
      });
  }

  enableEditMode(type: string) {
    this.editMode = type;
  }

  updateUser(updatedField: any) {
    // set edit mode to nothing again
    this.editMode = ''
    const updateObject = {
      // @ts-ignore
      [updatedField]: this.profileDetails[updatedField]
    }
    console.log("Updating user", updateObject);
    this.userService.updateUser(updateObject).subscribe((response: any) => {
    });
  }
  postData(){
    let name=this.profile.get('name')?.value || this.profileDetails.name;
     let phone=this.profile.get('phone')?.value || this.profileDetails.phone;
    let zipcode=this.profile.get('zipcode')?.value || this.profileDetails.zipCode ;
    let city=this.profile.get('city')?.value || this.profileDetails.city;
    console.log(zipcode)
    if(zipcode===null && city===null ){
      this.toaster.error('Please enter zip code and city')
    }
    else if(zipcode===null){
      this.toaster.error('Please enter zip code')
    }
    else if(city===null){
      this.toaster.error('Please enter city')
    }else if(name===null){
       this.toaster.error('Please enter name')
    }else if(phone===null){
       this.toaster.error('Please enter phone number')
    }
    else{
    const updateObject = {
      // @ts-ignore
      name:name,
      phone:phone,
      city:city,
      zipCode:zipcode.toString()
    }
    console.log("Updating user", updateObject);
    this.userService.updateUser(updateObject).subscribe((response: any) => {
        this.userService.setUser(response.data)
         location.reload();
    });
    }
   
  }
  changePassword() {
    if (!this.passwordChangeForm.valid) {
      this.toaster.info("Make sure all the fields are filled")
    } else if (this.passwordChangeForm.value.newPassword !== this.passwordChangeForm.value.confirmNewPassword) {
      this.toaster.info("New Password and Confirm New Password should be same")
    } else {
      let data = this.passwordChangeForm.value;
      data = {
        oldPassword: data.oldPassword,
        newPassword: data.newPassword
      }
      console.log("data from login form is", data);
      this.userService.changePassword(data).subscribe((response) => {

        if (response.data) {
          this.closePasswordModal.nativeElement.click();
           this.toaster.info("Password changes successfully")
           this.passwordChangeForm.reset();
        } else {
          this.toaster.info(response.status.message)
        }
        // const token = response.headers.get("Authorization");
        // localStorage.setItem("token", token);
      });
    }
  }

}
