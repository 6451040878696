import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  marginSortOrder = ["Low", "Med", "High"];
  allColumns = [
    {
      headerName: "Name", field: 'ownerName', checkboxSelection: true,
      headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true,
    },
    {headerName: 'Address', field: 'shortAddress'},
    {headerName: 'Sq Ft', field: 'squareFootArea', cellRenderer: this.sqFtCellRenderer},
    {headerName: 'Date', field: 'date'},
    {headerName: 'Motivations', field: 'motivations', cellRenderer: this.motivationsIconGenerator},
    {
      headerName: 'Margin',
      field: 'margin',
      cellRenderer: this.marginCellIconGenerator,
      comparator: (valueA: string, valueB: string, nodeA: any, nodeB: any, isInverted: any) => {
        if (valueA === valueB) {
          return 0;
        } else {
          return this.marginSortOrder.indexOf(valueA) - this.marginSortOrder.indexOf(valueB);
        }
      }
    },
    {headerName: 'Sale Price', field: 'salePrice', cellRenderer: this.priceCellRenderer.bind(this)},
    {headerName: 'Improvement Value', field: 'improvementValue', cellRenderer: this.priceCellRenderer.bind(this)},
    {headerName: 'Land Value', field: 'landValue', cellRenderer: this.priceCellRenderer.bind(this)},
    {headerName: 'Last Year Tax', field: 'lastYearTax', cellRenderer: this.priceCellRenderer.bind(this)},
    {headerName: 'Last Sale Date', field: 'lastSaleDate'},
    {headerName: 'Year Built', field: 'yearBuilt'},
    {headerName: 'Municipality Name', field: 'municipalityName'},
    {headerName: 'Longitude', field: 'longitude'},
    {headerName: 'Latitude', field: 'latitude'},
    {headerName: 'Match Summary', field: 'matchSummary'},
    {headerName: 'Number Of Units', field: 'numberOfUnits'},
    {headerName: 'Tax Record Link', field: 'taxRecordLink'},
    {headerName: 'Owner1', field: 'owner1'},
    {headerName: 'Owner2', field: 'owner2'},
    {headerName: 'Owners Category', field: 'ownersCategory'},
  ];

  constructor() {
  }

  // generate sqFt table cell with icon
  sqFtCellRenderer(params: { value: string; }) {
    return `<img src="../../../../assets/images/dashboard/sqft.svg"/> ${params.value}`;
  }

  // generate price table cell with $ icon
  priceCellRenderer(params: { value: string; }) {
    let price = params.value;
    price = price + ''; // convert price to string if it's a number
    let priceParts = price.split('.');
    price = priceParts[0];
    let counter = 3;
    while (counter < price.length) {
      price = `${price.substring(0, price.length - counter)},${price.substring(price.length - counter, price.length)}`
      counter = counter + 4;
    }
    return '$' + price + (priceParts[1] ? `.${priceParts[1]}` : '');
  }

// generate table cell icons for motivations
  motivationsIconGenerator(params: { value: [] }) {
    let icons = '';
    if (params.value.length > 0) {
      params.value.forEach((data: { icon: string, label: string, }, index: number) => {
        icons += `<img style="position: absolute; height: 30px; top: 15%; left: ${index * 16 + 6}%;"
      title="${data.label}" src="../../../../assets/images/${data.icon}"/>`;
      })
    }
    return icons;
  }

// generate table cell icons for motivations
  marginCellIconGenerator(params: { value: string; }) {
    const iconUrl = `../../../../assets/images/dashboard/sub-header/trending-${params.value.toLowerCase()}.png`;
    if (params.value === 'High') {
      return `<span style="color: #07A287; background-color: rgba(7, 162, 135, 0.08); border-radius: 4px;">
       <img style=" height: 20px;" src="${iconUrl}"/>
        High</span>`;
    } else if (params.value === 'Med') {
      return `<span style="color: #FCA549; background-color: rgba(252, 165, 73, 0.08); border-radius: 4px;">
       <img style="height: 20px;" src="${iconUrl}"/> Med`;
    } else {
      return `<span style="color: #757D8A; background-color: rgba(117, 125, 138, 0.08); border-radius: 4px;">
       <img style="height: 20px;" src="${iconUrl}"/> Low`;
    }
  }

  formatPriceString(price: any) {
    price = price + ''; // convert price to string if it's a number
    let priceParts = price.split('.');
    price = priceParts[0];
    let counter = 3;
    while (counter < price.length) {
      price = `${price.substring(0, price.length - counter)},${price.substring(price.length - counter, price.length)}`
      counter = counter + 4;
    }
    return '$' + price + (priceParts[1] ? `.${priceParts[1]}` : '');
  }

  getDefaultLeadCols(viewType: string) {
    console.log(this.allColumns)
    let cols;
    if (viewType === 'list') {
      cols = [...this.allColumns.slice(0, 12)]
      cols[1].field = 'address';
    } else {
      cols = [...this.allColumns.slice(0, 7)]
    }
    const result = [cols, cols.map((data) => data.field)]
    return result;
  }

  updateLeadCols(values: any) {
    // if(values==={}){
       console.log(values)
    let data= [...this.allColumns.filter((data) => values.includes(data.field))];
     console.log(data)
    return data;
    // }else{
    //   let data=this.allColumns.slice(0, 12);
    //   return data;
    // }
   
   
  }

  getLeadColOptions() {
    let data= this.allColumns.map((data) => {
      return {
        label: data.headerName,
        value: data.field
      }
    })
    return data;
  }

  getRowStyles() {
    return {
      'font-family': 'DM Sans',
      'font-weight': '400',
      'font-size': '12px',
      'line-height': '15.62px'
    }
  }
}
