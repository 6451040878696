import {NgModule} from '@angular/core';
// angular data tables for the list view of leads
import {AgGridModule} from "ag-grid-angular";
import {CommonModule} from "@angular/common";
import {ProfileComponent} from "./profile/profile.component";
import {SubscriptionComponent} from "./subscription/subscription.component";
import {SavedLeadsComponent} from './saved-leads/saved-leads.component';
import {SearchHistoryComponent} from './search-history/search-history.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgxStripeModule} from "ngx-stripe";
import {Select2Module} from "ng-select2-component";
import { ComponentsModule } from '../components.module';

@NgModule({
  exports: [
    ProfileComponent,
    SubscriptionComponent
  ],
  declarations: [
    ProfileComponent,
    SubscriptionComponent,
    SavedLeadsComponent,
    SearchHistoryComponent,

  ],
    imports: [
        AgGridModule.withComponents([]),
        CommonModule,
        FormsModule,
        NgxStripeModule.forRoot('pk_test_51JFhGCSCk5VBsSHPJ00odC0SIFDAJ5QM2kXUSq6GWkoIN6AIfZdmw3aDoX9HSVl3IMOPt1GGZE4ESMLHKbg0Lmk800A6tU5D12'),
        ReactiveFormsModule,
        Select2Module,
        ComponentsModule
    ],
  providers: [],
})
export class ProfileDrawerModule {
}
