import {Component, OnInit} from '@angular/core';
import {LeadsService} from "../../../services/leads.service";
import {HelperService} from "../../../services/helper.service";
import {Select2Data} from "ng-select2-component";
import { FormBuilder, FormGroup } from '@angular/forms';
import {ToastrService} from "ngx-toastr";
import {DownloadService} from "../../../services/download.service";
import {LoaderService} from "../../../services/loader.service";
import {UserService} from "../../../services/user.service";

@Component({
  selector: 'app-saved-leads',
  templateUrl: './saved-leads.component.html',
  styleUrls: ['./saved-leads.component.scss']
})
export class SavedLeadsComponent implements OnInit {

  constructor(private leadsService: LeadsService, private helperService: HelperService,private formbuilder: FormBuilder,
    private toaster: ToastrService, private downloadService: DownloadService,private loaderService: LoaderService,private userService: UserService) {
    [this.columnDefs, this.selectedValue] = this.helperService.getDefaultLeadCols('saved-leads')
    
  }

  // for select2 dropdown
  selectedValue: any;
  leadColOptions: any = this.helperService.getLeadColOptions();
  // component and table opts
  leads!: any;
  private gridApi: any;
  private gridColumnApi: any;
  defaultColumnDefs = {
    filter: true,
    sortable: true,
    filterParams: {
      buttons: ["apply", "clear", "cancel"],
      closeOnApply: true,
    }
  }
  rowStyles = this.helperService.getRowStyles();
  columnDefs: any;
  rowData: any;

  ArrayOfLeads=[];
  saveLeadSorting=[];
  selectedLeads = [];
  filterSaveLeads!: FormGroup;


  ngOnInit(): void {
    this.leadsService.getSavedLeads().subscribe((res) => {
      this.rowData = res.data;
    })
     this.InitLeads();
  }


  onGridReady(params: { api: any; columnApi: any; }) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onSelectionChanged() {
    let selectedRows = this.gridApi.getSelectedRows();
     this.selectedLeads = selectedRows;
    console.log(selectedRows);
  }

   saveLeads() {
    if (this.selectedLeads.length === 0) {
      this.toaster.info("Please select at least one lead");
      return 0;
    }
    // @ts-ignore
    const selectedLeadIds = this.selectedLeads.map((lead) => lead.id);
    console.log("Leads to be saved", selectedLeadIds)
    this.leadsService.saveLeadData({
      isSaved: true,
      leadIds: selectedLeadIds
    }).subscribe((response) => {
      if (response.data) {
        this.gridApi.deselectAll();
        this.toaster.info("Your leads saved successfully");
      } else
        this.toaster.error("Error in saving leads, Please try again!")
    });
    return 1;
  }

  downloadPdf() {
    let user =JSON.parse(localStorage.getItem("user") || '');
    if (this.selectedLeads.length === 0) {
      this.toaster.info("Please select at least one lead");
      return 0;
    }
     if(user.threshold>this.selectedLeads?.length){
    this.toaster.info("Please wait while we are processing your download");
    this.loaderService.isLoading.next(true);
    let data = this.selectedLeads;
    data = data.map((item) => {
      // @ts-ignore
      delete item.shortAddress;
      // @ts-ignore
      delete item.motivations;
      return item;
    })
    this.downloadService.downloadFile(data);
    this.loaderService.isLoading.next(false);
    this.userService.updateThreshold(this.selectedLeads?.length).subscribe((response: any) => {
        this.userService.setUser(response.data)
    });
  
    // const doc = new jsPDF('landscape', 'pt', 'A1');
    // const colsValues = Object.keys(data[0]);
    // const cols = colsValues.map((col) => {
    //   return {
    //     header: col,
    //     dataKey: col
    //   }
    // })
    // // @ts-ignore
    // doc.autoTable({
    //   columns: cols, body: data, tableWidth: 2000,
    // });
    // doc.save('leads.pdf');
    return 1;
  } else{
      this.toaster.info("Please Increse Threshold to download leads");
      return 0;
    }
  
  }
  

   sendLeadsEmail() {
    console.log("Sending Leads", this.selectedLeads.length);
    if (this.selectedLeads.length > 50) {
      this.toaster.info("You can not email more than 50 leads at a time!");
    } else {
      this.userService.sendLeadsEmail(this.selectedLeads).subscribe((response: any) => {
        if (response.data) {
          this.gridApi.deselectAll();
          this.toaster.success("Selected leads sent to your registered email id");
        } else {
          this.toaster.error("Error in sending email, Pleas try again!");
        }
      });
    }
  }
    deleteSavedLeads() {
    console.log("Sending Leads", this.selectedLeads);
    if (this.selectedLeads.length > 50) {
      this.toaster.error("You can not delete more than 50 leads at a time!");
    } else {
      this.leadsService.deleteSavedLeads(this.selectedLeads).subscribe((response: any) => {
        if (response.data) {
         this.leadsService.getSavedLeads().subscribe((res) => {
       this.rowData = res.data;
      })
          this.gridApi.deselectAll();
          this.toaster.success("Selected leads are deleted successfully");
        } else {
          this.toaster.error("Error in deleting leads, Pleas try again!");
        }
      });
    }
  }
  changeLeadColumn() {
    this.columnDefs = this.helperService.updateLeadCols(this.selectedValue);
  }
  sortedSaveData(){
      let saveSortData = JSON.parse(localStorage.getItem('saveSortLeads') || '{}');
      if(saveSortData){
        this.selectedValue=saveSortData;
        this.changeLeadColumn();
        }else{
            [this.columnDefs, this.selectedValue] = this.helperService.getDefaultLeadCols('saved-leads')
        }
  }
    InitLeads(){
  //convert object to array

    this.ArrayOfLeads = this.leadColOptions.map(function (obj:any) {
    return obj.value;
    });
     //check for selected feilds in fillter
    let checkedFillter:any=[];
      for(let i=0;i<(this.ArrayOfLeads).length;i++){
        for(let j=0;j<(this.selectedValue).length;j++){
          if(this.ArrayOfLeads[i]===this.selectedValue[i]){
            checkedFillter[i]=true;
            break;
          }
        }
        if(!checkedFillter[i]){
            checkedFillter[i]=false;
          }
      }

    this.filterSaveLeads = this.formbuilder.group({
      leadSort: this.formbuilder.array(checkedFillter)
    });  

  }
  filterSaveLead(){
    let values= this.filterSaveLeads.value.leadSort;
    this.saveLeadSorting=[];
    for(let i=0;i<values.length;i++){
      if(values[i]===true){
        this.saveLeadSorting.push(this.ArrayOfLeads[i])
      }
    }
     this.selectedValue=this.saveLeadSorting;
     localStorage.setItem('saveSortLeads', JSON.stringify(this.selectedValue));
    this.changeLeadColumn();
  }
}
