import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AdminService} from "../../../services/admin.service";
import {ToastrService} from "ngx-toastr";
import {HelperService} from "../../../services/helper.service";
import {LoaderService} from "../../../services/loader.service";
declare var $: any;

@Component({
  selector: 'app-global-settings',
  templateUrl: './global-settings.component.html',
  styleUrls: ['./global-settings.component.scss']
})
export class GlobalSettingsComponent implements OnInit {
  settings = [];
  editMode: boolean;
  settingsForm = {name: '', settings: {value: ''}, effectiveDate: '',};
  @ViewChild('closeAddSettingsModal') closeAddSettingsModal: ElementRef
  // ag-table
  private gridApi: any;
  private gridColumnApi: any;
  defaultColumnDefs = {
    filter: true,
    sortable: true,
    filterParams: {
      buttons: ["apply", "clear", "cancel"],
      closeOnApply: true,
    }
  }
  rowStyles = this.helperService.getRowStyles();
  columnDefs = [
    {headerName: "Settings Name", field: 'name'},
    {headerName: "Status", field: 'status'},
    {headerName: 'Value', field: 'settings.value'},
    {headerName: 'Effective Date', field: 'effectiveDate'}
  ];

  constructor(private adminService: AdminService, private toaster: ToastrService, private loaderService: LoaderService,
              private helperService: HelperService) {
  }

  ngOnInit(): void {
    this.getAllSettings();

    // disable previous date selection
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, '0');
      let mm = String(today.getMonth() + 1).padStart(2, '0');
      let yyyy = today.getFullYear();

      let todayDate = yyyy + '-' + mm + '-' + dd;
      $('#date').attr('min',todayDate);
    // 
  }

  toggleEditMode() {
    this.editMode = !this.editMode;
  }

  onGridReady(params: { api: any; columnApi: any; }) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  private getAllSettings() {
    this.adminService.getAllSettings().subscribe((response) => {
      if (response.data) {
        this.settings = response.data;
      } else {
        this.toaster.error("Error in fetching settings");
      }
    });
  }

  public addNewSetting() {
    console.log("Called add new setting", this.settingsForm)
    if (this.settingsForm.effectiveDate && this.settingsForm.effectiveDate.length === 0) {
      // @ts-ignore
      delete this.settingsForm.effectiveDate;
    }
    this.adminService.addSetting(this.settingsForm).subscribe((response) => {
      this.loaderService.isLoading.next(true);
      if (response.data) {
        this.toaster.success("Settings added successfully");
        // @ts-ignore
        this.settings.push(response.data)
        this.settings = [...this.settings];
        this.settingsForm = {name: '', settings: {value: ''}, effectiveDate: '',};
        this.closeAddSettingsModal.nativeElement.click();
      } else {
        if(this.settingsForm.name===''){
          this.toaster.error("Select name for creating new setting");
        }
        else if(this.settingsForm.settings.value===''){
          this.toaster.error("Enter value for creating new setting");
        }
        else if(this.settingsForm.name==='' && this.settingsForm.settings.value===''){
          this.toaster.error("Select name and value for creating new setting");
        }else{
          this.toaster.error("Error in creating new setting");
        }
      }
    });
  }
}
