import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from "./components/home/home.component";
import {DashboardComponent} from "./components/dashboards/dashboard/dashboard.component";
import {SubscriptionComponent} from "./components/profileDrawer/subscription/subscription.component";
import {ProfileComponent} from "./components/profileDrawer/profile/profile.component";
import {SavedLeadsComponent} from "./components/profileDrawer/saved-leads/saved-leads.component";
import {SearchHistoryComponent} from "./components/profileDrawer/search-history/search-history.component";
import {SearchUserComponent} from "./components/admin/search-user/search-user.component";
import {GlobalSettingsComponent} from "./components/admin/global-settings/global-settings.component";
import {AuthGuard} from "./auth/auth.guard";
import {SubscriptionGuard} from "./auth/subscription.guard";
import {FiltersComponent} from './components/dashboards/filters/filters.component';
import {SurveyActionComponent} from "./components/admin/survey-action/survey-action.component";
import { ResetPasswordComponent } from './reset-password/reset-password.component';

const routes: Routes = [
  {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard, SubscriptionGuard]},
  {path: 'profile', component: ProfileComponent, canActivate: [AuthGuard]},
  {path: 'subscriptions', component: SubscriptionComponent, canActivate: [AuthGuard]},
  {path: 'users', component: SearchUserComponent, canActivate: [AuthGuard]},
  {path: 'survey-action', component: SurveyActionComponent, canActivate: [AuthGuard]},
  {path: 'settings', component: GlobalSettingsComponent, canActivate: [AuthGuard]},
  {path: 'saved-leads', component: SavedLeadsComponent, canActivate: [AuthGuard, SubscriptionGuard]},
  {path: 'search-history', component: SearchHistoryComponent, canActivate: [AuthGuard, SubscriptionGuard]},
  {path: 'filters', component: FiltersComponent},
  {path:'reset-password',component:ResetPasswordComponent},
  {path: '**', component: HomeComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
