 
    <app-header *ngIf="isUserLoggedIn"></app-header>
    <router-outlet></router-outlet>
    <!-- <app-loader></app-loader> -->
 

<div class="modal fade" id="myModal" data-backdrop="static" data-keyboard="false">
     <div class="modal-dialog modal-dialog-centered">
       <div class="modal-content">
          <h1>Connection Lost!</h1>
          <p>Please Check your Internet Connection</p>
       </div>
     </div>
   </div>
