import {Component, Inject, OnInit} from '@angular/core';
import {LeadsService} from "../../../services/leads.service";
import {ToastrService} from "ngx-toastr";
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-survey-action',
  templateUrl: './survey-action.component.html',
  styleUrls: ['./survey-action.component.scss']
})
export class SurveyActionComponent implements OnInit {

  surveyData: any;
  leadAddress:any;
  // ag-table
  // private gridApi: any;
  // private gridColumnApi: any;
  // defaultColumnDefs = {
  //   filter: true,
  //   sortable: true,
  //   filterParams: {
  //     buttons: ["apply", "clear", "cancel"],
  //     closeOnApply: true,
  //   },
  //   resizable: true,
  //   draggable: true
  // }
  // rowStyles = this.helperService.getRowStyles();
  // columnDefs = [
  //   {headerName: "id", field: 'id'},
  //   {headerName: 'leadId', field: 'leadId'},
  //   {headerName: 'purchased', field: 'purchased'},
  //   {headerName: 'closed', field: 'closed'},
  //   {headerName: 'Action', field: 'isReviewed', cellRenderer: this.actionButtons},
  // ];

  constructor(private leadsService: LeadsService, private toaster: ToastrService,@Inject(DOCUMENT) private document: Document,) {
  }

  ngOnInit(): void {
    this.fetchAllSurvey();
    this.document.body.classList.remove('body-overflow');
  }

  fetchAllSurvey() {
    this.leadsService.getAllLeadSurvey().subscribe((response) => {
      if (response.data){
        console.log(response.data.surveys)
        this.surveyData = response.data
      }
      else {
        const message = response.status.message;
        this.toaster.info(message);
      }
    })
  }

  updateLeadSurvey() {
    console.log("Changed Survey data", this.surveyData);

    const updatedLeads = this.surveyData.filter((survey: { isAccepted: string; }) => ["true", "false"].includes(survey.isAccepted));
    console.log("Changed Survey data", updatedLeads);
    this.leadsService.leadSurveyAction(updatedLeads).subscribe((response: any) => {
      if (response.data){
           this.toaster.success("Lead survey detail updated successfully");
           this.fetchAllSurvey();
      }
      else
        this.toaster.error("Error in updating lead survey, Please try again");
    })
  }

  // onGridReady(params: { api: any; columnApi: any; }) {
  //   this.gridApi = params.api;
  //   this.gridColumnApi = params.columnApi;
  // }
  //
  //  actionButtons(params: { value: string }) {
  //    return `<div><select style="width: 50%;text-indent: 1px;"
  // [(ngModel)]="surveyAction"><option value="${params.value}">Decline</option><option value=${!params.value}> Accept</option></select></div>`;
  //  }
}
