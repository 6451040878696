<div class="searchUser">
  <div class="container">
    <div class="backHome">
      <a href="/dashboard" (click)="removeSearch()" class="btn back">
        <img src="assets/images/home.png">Back To Home
      </a>
    </div>
    <div class="users">
      <div class="headPart" *ngIf="! searchUserHistory">
        <h4>Search Users</h4>
        <div class="form-group">
          <input (keyup.enter)="searchUsers()" [(ngModel)]="searchText" class="form-control" type="text"
                 placeholder="Search by name, email or userId">
          <img src="../../../assets/images/dashboard/header/search.svg" alt="search" class="searchBtn"
               (click)="searchUsers()">
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 col-sm-12 col-12 mx-auto" *ngIf="!currentUser || !(editUser || searchUserHistory)">
        <ag-grid-angular
          style="width: 100%; height: 600px;"
          class="ag-theme-material"
          [rowData]="users"
          [defaultColDef]="defaultColumnDefs"
          [columnDefs]="columnDefs"
          [rowStyle]="rowStyles"
          rowSelection="single"
          [suppressCellSelection]=true
          [rowMultiSelectWithClick]="true"
          (gridReady)="onGridReady($event)">
        </ag-grid-angular>
      </div>
      <div class="col-lg-12 col-sm-12 col-12" *ngIf="currentUser && currentUser.name && editUser && !searchUserHistory">
        <!-- User edit div starts -->
        <div class="userUpdate" >
          <div class="row">
            <div class="col-lg-6 col-sm-6 col-12">
              <div class="form-group">
                <label>Name</label>
                <input [(ngModel)]="currentUser.name" type="text" class="form-control" placeholder="Name">
              </div>
            </div>

            <div class="col-lg-6 col-sm-6 col-12">
              <div class="form-group">
                <label>Email</label>
                <input [(ngModel)]="currentUser.email" type="text" class="form-control" placeholder="Email">
              </div>
            </div>

            <div class="col-lg-6 col-sm-6 col-12">
              <div class="form-group">
                <label>Phone</label>
                <input [(ngModel)]="currentUser.phone" type="text" class="form-control" placeholder="Phone">
              </div>
            </div>

            <div class="col-lg-6 col-sm-6 col-12">
              <div class="form-group">
                <label>Status</label>
                <select [(ngModel)]="currentUser.status" class="form-control">
                  <option value="ACTIVE">ACTIVE</option>
                  <option value="INACTIVE">INACTIVE</option>
                </select>
              </div>
            </div>

            <div class="col-lg-6 col-sm-6 col-12">
              <div class="form-group">
                <label>City</label>
                <input [(ngModel)]="currentUser.city" type="text" class="form-control" placeholder="City">
              </div>
            </div>

            <div class="col-lg-6 col-sm-6 col-12">
              <div class="form-group">
                <label>Country</label>
                <input [(ngModel)]="currentUser.county" type="text" class="form-control" placeholder="County">
              </div>
            </div>

            <div class="col-lg-6 col-sm-6 col-12">
              <div class="form-group">
                <label>Zip Code</label>
                <input [(ngModel)]="currentUser.zipCode" type="text" class="form-control" placeholder="Zip Code">
              </div>
            </div>

            <div class="col-lg-6 col-sm-6 col-12">
              <div class="form-group">
                <label>Payment Amount</label>
                <input [(ngModel)]="currentUser.paymentAmount" type="text" class="form-control" placeholder="Payment Amount">
              </div>
            </div>

            <div class="col-lg-6 col-sm-6 col-12">
              <div class="form-group">
                <label>Threshold</label>
                <input [(ngModel)]="currentUser.threshold" type="text" class="form-control" placeholder="Threshold">
              </div>
            </div>
            
            <div class="col-lg-6 col-sm-6 col-12">
              <div class="btngroup">
                <button (click)="exit()" class="btn cancelBtn">Cancel</button>
                <button (click)="updateUser()" class="btn updateBtn">Update</button>
              </div>
            </div>    
          </div>
          
        </div>
        <!-- User edit div ends -->
      </div>
    </div>
  </div>
</div>




<!-- search history starts -->

<div class="searchHistory">
  <div class="container"  *ngIf="currentUser && currentUser.name  && searchUserHistory">
    <div class="content">
      <div class="d-flex justify-content-between align-items-center mb-4">
        <h4>Search History</h4>
        <button (click)="backHistory()" class="btn cancelBtn">Back</button>
      </div>
      <div class="heading">
        <p>Search Name</p>  
        <p class="pr-4">Date</p>
        <p>Search</p>
      </div>
      <div class="historyCard" *ngIf="userHistory!==0" >
        <div class="cards" *ngFor="let history of userHistory">
          <p>{{history.searchText}}</p>
          <p>{{history.date}}</p>
          <button class="btn searchBtn" (click)="searchById(history)">Search</button>
        </div>
      </div>
       <div class="historyCard" *ngIf="userHistory===0" >
        <div class="cards">
          <p>No History Result Found</p>
        </div>
      </div>
    </div>

  </div>
</div>

<!-- search history ends -->